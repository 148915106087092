import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { groupBy, map, sumBy } from 'lodash';
import { Grid } from '@mui/material';
import Gallons from '../../../components/views/dashboard/Gallons';
import RetailSpend from '../../../components/views/dashboard/RetailSpend';
import Transactions from '../../../components/views/dashboard/Transactions';
import { GET_DASHBOARD_INFO } from '../../../services/graphql/queries/getDashboardInfo';
import { MostActiveLocations } from '../../../components/views/dashboard/MostActiveLocations';
import { Financial } from './components/Financial';

export const Dashboard = () => {
  const [selectedIndex, setSelectedIndex] = useState('DAY');
  const { carrierUuid } = useParams();

  const [getDasbhoardInfo, { data }] = useLazyQuery(GET_DASHBOARD_INFO, {
    fetchPolicy: 'cache-first',
  });

  const mostActiveLocations = useMemo(() => {
    return data?.getDashboardInfo?.mostActiveLocations;
  }, [data?.getDashboardInfo?.mostActiveLocations]);

  useEffect(() => {
    getDasbhoardInfo({
      variables: {
        parentUuid: carrierUuid,
        timeFrame: selectedIndex,
      },
    });
  }, [carrierUuid, selectedIndex]);

  const chartData = useMemo(() => {
    if (selectedIndex === 'DAY') {
      return data?.getDashboardInfo?.dataPoints;
    }

    const groupedByDate = groupBy(
      data?.getDashboardInfo?.dataPoints,
      (item) => item.time.split('T')[0],
    );

    // Takes objects grouped by date and adds the sum of gallons
    // and dollars so only one tick appears on the line chart
    const groupedArray = map(groupedByDate, (group, date) => {
      const dateObjectUTC = new Date(date);
      const dateObjectLocal = new Date(
        dateObjectUTC.getTime() + dateObjectUTC.getTimezoneOffset() * 60000,
      );
      const accurateUTCDateString = dateObjectLocal.toISOString();
      return {
        time: accurateUTCDateString,
        gallon: sumBy(group, 'gallon'),
        dollar: sumBy(group, 'dollar'),
      };
    });

    return groupedArray;
  }, [data?.getDashboardInfo, selectedIndex]);

  const dashboardData = useMemo(() => {
    return data?.getDashboardInfo;
  }, [data]);

  return (
    <>
      <Financial />
      <Transactions
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        chartData={chartData}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <MostActiveLocations
            mostActiveLocations={mostActiveLocations}
            selectedIndex={selectedIndex}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Gallons
            dashboardData={dashboardData}
            selectedIndex={selectedIndex}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RetailSpend
            dashboardData={dashboardData}
            selectedIndex={selectedIndex}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
