import { Typography, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { commonStyles } from './shared/commonStyles';
import { getDuration } from './shared/getDuration';

const pattern = /^([a-zA-Z\s]+)(\d+)$/;

interface MostActiveLocationsProps {
  mostActiveLocations: string[];
  selectedIndex: string;
}

export const MostActiveLocations: FC<MostActiveLocationsProps> = ({
  mostActiveLocations,
  selectedIndex,
}) => {
  return (
    <Box sx={commonStyles.container}>
      <Typography sx={commonStyles.title}>Most Active Locations</Typography>
      <Box sx={commonStyles.content}>
        <Typography sx={commonStyles.textCenter}>
          LAST {getDuration(selectedIndex)}
        </Typography>
        {!isEmpty(mostActiveLocations) ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {mostActiveLocations.map((location: string) => {
              const string = pattern.exec(location);
              let parsedString = location;

              if (string) {
                parsedString = `${string[1].trim()} ${string[2]}`;
              }

              return (
                <Typography
                  key={location}
                  bgcolor="#D3D3D3B3"
                  fontSize={13}
                  fontWeight={600}
                  sx={{
                    my: 1,
                    mx: 1,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    alignSelf: 'flex-start',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {parsedString}
                </Typography>
              );
            })}
          </Box>
        ) : (
          <Typography sx={commonStyles.value}>N/A</Typography>
        )}
      </Box>
    </Box>
  );
};
