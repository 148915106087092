import { Box, Button, Grid, CircularProgress } from '@mui/material';

interface FormFooterPropsType {
  handleBack: () => void;
  handleSubmit: () => void;
  activeStep: number;
  isLastStep: boolean;
  isSubmitting: boolean;
}

export function FormFooter(props: FormFooterPropsType) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            color="inherit"
            onClick={props.handleBack}
            sx={{ p: 2, width: '100%' }}
            disabled={props.isSubmitting || props.activeStep === 0}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault(); // Prevent form submission
              props.handleSubmit();
            }}
            sx={{
              background: '#181818',
              color: '#fff',
              width: '100%',
              fontWeight: '700',
              '&:hover': { background: '#FFF800', color: '#181818' },
              p: 2,
            }}
          >
            {props.isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : props.isLastStep ? (
              'SUBMIT'
            ) : (
              'Next'
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
