import React from 'react';
import { useFormikContext } from 'formik';
import { Grid, Typography } from '@mui/material';

import BusinessDetails from './BusinessDetails';
import OwnerDetails from './OwnerDetails';
import FleetDetails from './FleetDetails';

export default function ConfirmStep() {
  const { values: formValues } = useFormikContext();

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item container direction="column" xs={12} mt={2}>
        <Typography variant="h5" align="left">
          Is everything correct? Please review your information before
          submitting.
        </Typography>
      </Grid>

      <BusinessDetails formValues={formValues} />
      <FleetDetails formValues={formValues} />
      <OwnerDetails formValues={formValues} />
    </Grid>
  );
}
