import React from 'react';
import { Typography, Grid } from '@mui/material';

function BusinessDetails(props: any) {
  const { formValues } = props;

  const {
    businessName,
    businessEmail,
    businessPhoneNumber,
    businessAddress1,
    businessAddress2,
    businessRegion,
    businessCity,
    businessZipCode,
    businessCountry,
    businessEin,
    primaryContactName,
    primaryContactEmail,
    primaryContactPhoneNumber,
    promotionCode,
  } = formValues;

  return (
    <Grid item container direction="column" xs={12} mt={2}>
      <Typography variant="h6" gutterBottom fontWeight={500}>
        Business Details
      </Typography>
      <Grid container>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Name:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessName}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Email:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessEmail}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Phone Number:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessPhoneNumber}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>EIN:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessEin}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Address1:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessAddress1}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Country:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessCountry}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Address2:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessAddress2}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>City:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessCity}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Postal / Zip Code:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessZipCode}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Region / State:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{businessRegion}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Contact Name:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{primaryContactName || '-'}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Contact Email:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{primaryContactEmail || '-'}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Contact Phone Number:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>
              {primaryContactPhoneNumber || '-'}
            </Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Promotion Code:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{promotionCode || '-'}</Typography>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
}

export default BusinessDetails;
