import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Config } from '../config/env';

function useCarrier() {
  const [carrier, setCarrier] = useState<any>({});

  const { carrierUuid } = useParams();

  useEffect(() => {
    const getData = async () => {
      if (carrierUuid === undefined) throw Error('missing carrierUuid');

      await axios
        .get(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}`)
        .then((response) => {
          setCarrier(response.data.carrier);
        })
        .catch((error) => error);
    };
    getData();
  }, [carrierUuid !== undefined]);

  return carrier;
}

export default useCarrier;
