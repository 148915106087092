import axios, { AxiosResponse } from 'axios';
import { Config } from '@/fleet/config/env';

const apiUrl = Config.CARRIER_API_URL;

export const createCreditApplicationService = async (
  payload: any,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/applications/credit`, payload)
    .then((response) => response)
    .catch((error) => error);
};
