import React from 'react';

import { styled, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';
import { Grid } from '@mui/material';

import { Outlet } from 'react-router-dom';
import { DrawerHeader } from '@/fleet/components/navigation/Drawer';
import { PublicAppBar } from '@/fleet/components/navigation/PublicAppBar';
import { PublicAppBarV2 } from '@/fleet/components/navigation/PublicAppBarV2';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  background: '#f8f8fa',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
}));

const thm = createTheme({
  palette: {
    background: {
      default: '#f8f8fa',
    },
  },
});

type LayoutPublicPropsType = {
  isV2?: true;
};

export function LayoutPublic(props: LayoutPublicPropsType) {
  return (
    <ThemeProvider theme={thm}>
      <CssBaseline />

      <Box sx={{ display: 'flex' }}>
        {props.isV2 ? <PublicAppBarV2 /> : <PublicAppBar />}
        <Main>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <DrawerHeader />
            <Outlet />
          </Grid>
        </Main>
      </Box>
    </ThemeProvider>
  );
}
