import axios, { AxiosResponse, AxiosError } from 'axios';
import { Config } from '../config/env';

const apiUrl = Config.CARRIER_API_URL;

export const listDriversService = async (
  carrierUuid: string | undefined,
): Promise<AxiosResponse | AxiosError> => {
  if (carrierUuid === undefined) throw Error('missing carrierUuid');

  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}/drivers`)
    .then((response) => response)
    .catch((error) => error);
};

export const deactivateDriverService = async (
  carrierUuid: string | undefined,
  driverUuid: string | undefined,
  identityUuid: string,
): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .post(
      `${apiUrl}/carriers/${carrierUuid}/drivers/${driverUuid}/deactivate`,
      { identityUuid },
    )
    .then((response) => response)
    .catch((error) => error);
};

export const activateDriverService = async (
  carrierUuid: string | undefined,
  driverUuid: string | undefined,
  identityUuid: string,
): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/drivers/${driverUuid}/activate`, {
      identityUuid,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const addDriverService = async (values: any): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${values.carrierUuid}/drivers`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const setPasswordService = async (
  values: any,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/users/password`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const updateDriver = async (
  values: { email: string; firstName: string; lastName: string },
  carrierUuid: string | undefined,
  driverUuid: string | undefined,
): Promise<AxiosResponse> => {
  const payload = {
    carrierUuid: carrierUuid,
    driverUuid: driverUuid,
    newEmail: values.email,
    newFirstName: values.firstName,
    newLastName: values.lastName,
  };
  return await axios
    .post(`${apiUrl}/${carrierUuid}/drivers/${driverUuid}`, payload)
    .then((response) => response)
    .catch((error) => error);
};
