export function getDuration(selectedIndex: string) {
  switch (selectedIndex) {
    case 'DAY':
      return '24 HRS';
    case 'WEEK':
      return '7 DAYS';
    case 'MONTH':
      return '30 DAYS';
    default:
      return '';
  }
}
