import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/material';

export const stepStyles: SxProps = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: '#181818',
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    color: '#181818',
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: '#FFF800',
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: '#181818',
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: '#181818',
  },
};

export const useFormStyles = makeStyles(() => ({
  textField: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  checkbox: {
    padding: '10px',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: '20px',
  },
}));
