import { Box, Typography } from '@mui/material';

type LimitRowPropsType = {
  field: string;
  balanceValue: number;
  limitValue: number;
  remainingBalance: number;
};

const parseAndFormatValue = (
  value: number,
  adornment: string | null,
): string | number => {
  return adornment ? (value % 1 !== 0 ? value.toFixed(2) : value) : value;
};

export const LimitRow = ({
  field,
  balanceValue,
  limitValue,
  remainingBalance,
}: LimitRowPropsType) => {
  const formattedFieldString = field
    .replace(/Qty|Balance/g, '')
    .replace(/(?=[A-Z])/g, ' ');

  const adornment = field.includes('Qty') ? null : '$';
  const endAdornment = adornment ? null : 'gal';

  const parsedLimitValue = parseAndFormatValue(limitValue, adornment);
  const parsedBalanceValue = parseAndFormatValue(balanceValue, adornment);
  const parsedRemainingBalance = parseAndFormatValue(
    remainingBalance,
    adornment,
  );

  return (
    <Box
      p={2.5}
      mb={2}
      bgcolor="#D3D3D359"
      borderRadius={2}
      justifyContent="space-between"
      display="flex"
      flexDirection="row"
    >
      <Typography textTransform="capitalize" width="25%">
        {formattedFieldString}
      </Typography>
      <Typography sx={{ width: '25%', textAlign: 'end' }}>
        {adornment}
        {parsedLimitValue.toLocaleString()}
      </Typography>
      <Typography sx={{ width: '25%', textAlign: 'end' }}>
        {adornment}
        {parsedBalanceValue.toLocaleString()}
      </Typography>
      <Typography sx={{ width: '25%', textAlign: 'end' }}>
        {adornment}
        {parsedRemainingBalance.toLocaleString()} {endAdornment}
      </Typography>
    </Box>
  );
};
