import axios, { AxiosResponse } from 'axios';
import { Config } from '../config/env';

const apiUrl = Config.REPORT_API_URL;

export const listCarrierTransactionsService = async (
  carrierUuid: string,
  startDate: string,
  endDate: string,
  driverUuid?: string,
  unitNumber?: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/carriers/${carrierUuid}/transactions?startDateTime=${startDate}&endDateTime=${endDate}${driverUuid ? `&driverUuid=${driverUuid}` : ''}${unitNumber ? `&unitNumber=${unitNumber}` : ''}`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export const listDiscountReport = async (
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/reports/carriers/${carrierUuid}/earnedDiscounts`)
    .then((response) => response)
    .catch((error) => error);
};

export const listFuelTaxReport = async (
  carrierUuid: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/carriers/${carrierUuid}/fueltax?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
};
