import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_CARRIER_PROFILE } from '@/fleet/services/graphql/queries/getCarrierProfile';

const useCarrierProfile = () => {
  const [edParticipation, setEdParticipation] = useState<boolean | null>(null);

  const { carrierUuid } = useParams();
  const [getCarrierProfile, { data }] = useLazyQuery(GET_CARRIER_PROFILE);

  useEffect(() => {
    getCarrierProfile({ variables: { carrierUuid: carrierUuid } });
  }, [carrierUuid]);

  useEffect(() => {
    setEdParticipation(data?.getCarrierProfile?.edParticipation);
  }, [data?.getCarrierProfile?.edParticipation]);

  return { edParticipation };
};

export default useCarrierProfile;
