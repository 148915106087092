import * as Yup from 'yup';

export const limitValidationSchema = Yup.object().shape({
  limitType: Yup.string().required('Select a limit type'),
  tractorDiesel: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
  reeferDiesel: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
  def: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
  oil: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
  additives: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
  merchandise: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
  cashAdvance: Yup.string()
    .matches(/^\d+$/, 'Invalid number')
    .required('Required field'),
});
