import { Loader } from '@googlemaps/js-api-loader';
import { Config } from '@/fleet/config/env';

export type AddressSuggestionType = {
  street: string;
  placeId: string;
};

const loader = new Loader({
  apiKey: Config.GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
});

export const getZipCodeInfo = async (
  zipCode: string,
): Promise<{ city: string; state: string; country: string }> => {
  await loader.load();
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: zipCode, componentRestrictions: { country: 'US' } },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
          let city = '',
            state = '',
            country = '';
          for (const component of results[0].address_components) {
            if (component.types.includes('locality')) {
              city = component.long_name;
            }
            if (component.types.includes('administrative_area_level_1')) {
              state = component.short_name;
            }
            if (component.types.includes('country')) {
              country = component.long_name;
            }
          }
          resolve({ city, state, country });
        } else {
          reject(new Error('Unable to find location for the given ZIP code'));
        }
      },
    );
  });
};

export const getAddressSuggestions = async (
  input: string,
): Promise<google.maps.places.AutocompletePrediction[]> => {
  await loader.load();
  return new Promise((resolve, reject) => {
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input: input,
        componentRestrictions: { country: 'us' },
        types: ['address'],
      },
      (predictions, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          resolve(predictions);
        } else if (
          status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          resolve([]);
        } else {
          reject(new Error(`Place Autocomplete request failed: ${status}`));
        }
      },
    );
  });
};
