import React from 'react';
import { Grid } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import InputField from '@/fleet/components/common/fields/InputField';
import SelectField from '@/fleet/components/common/fields/SelectField';
import { States } from '@/fleet/components/helpers/States';

export default function BusinessInformation(props: any) {
  const {
    formField: {
      carrierType,
      businessName,
      businessEmail,
      businessPhoneNumber,
      businessAddress1,
      businessAddress2,
      businessRegion,
      businessCountry,
      businessZipCode,
      businessCity,
      businessEin,
      primaryContactName,
      primaryContactEmail,
      primaryContactPhoneNumber,
      promotionCode,
    },
  } = props;

  return (
    <>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputField
            name={promotionCode.name}
            label={promotionCode.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            name={carrierType.name}
            label={carrierType.label}
            data={[
              { value: 'owner_operator', label: 'Owner Operator (One driver)' },
              { value: 'carrier', label: 'Carrier (Multiple drivers)' },
            ]}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={businessName.name}
            label={businessName.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={businessEmail.name}
            label={businessEmail.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={businessPhoneNumber.name}
            label={businessPhoneNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={businessEin.name}
            label={businessEin.label}
            fullWidth
          />
          <FormHelperText>
            Used to confirm business, will not affect credit
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={businessAddress1.name}
            label={businessAddress1.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={businessAddress2.name}
            label={businessAddress2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            name={businessRegion.name}
            label={businessRegion.label}
            data={States.toOptions()}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={businessCity.name}
            label={businessCity.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={businessZipCode.name}
            label={businessZipCode.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={businessCountry.name}
            label={businessCountry.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={primaryContactName.name}
            label={primaryContactName.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={primaryContactEmail.name}
            label={primaryContactEmail.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={primaryContactPhoneNumber.name}
            label={primaryContactPhoneNumber.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
