import * as Yup from 'yup';

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .max(60, 'Must be 60 characters or less')
    .required('Required'),
  phone: Yup.string()
    .matches(/^\d{3}-\d{3}-\d{4}$/, 'Invalid phone number')
    .required('Required'),
  authorizedOfficer: Yup.boolean()
    .oneOf([true], 'You must be an authorized officer or owner')
    .required('Required'),
});
