import { fmcsaAxios } from '@/fleet/config/axios';
import { Config } from '@/fleet/config/env';

interface Carrier {
  allowedToOperate: string;
  legalName: string;
  dbaName: string;
  dotNumber: number;
  phyStreet: string;
  phyCity: string;
  phyState: string;
  phyZipcode: string;
  phyCountry: string;
  telephone?: string;
  ein?: number;
  safetyRatingDate: string;
  totalPowerUnits: number;
}

interface USDOTResponse {
  content: {
    carrier: Carrier;
    _links: {
      'docket numbers': {
        href: string;
      };
    };
  };
}

interface DocketNumber {
  docketNumber: number;
  prefix: string;
}

interface DocketNumbersResponse {
  content: DocketNumber[];
}

const FMCSA_API_URL = 'https://mobile.fmcsa.dot.gov/qc/services/carriers';

const usDotCache: Record<
  string,
  { carrier: Carrier; mcNumber: string | null }
> = {};

export const validateUSDOT = async (
  usDotNumber: string,
): Promise<{ carrier: Carrier; mcNumber: string | null } | null> => {
  if (usDotCache[usDotNumber]) {
    return usDotCache[usDotNumber];
  }

  try {
    const response = await fmcsaAxios.get<USDOTResponse>(
      `${FMCSA_API_URL}/${usDotNumber}?webKey=${Config.FMCSA_WEBKEY}`,
    );

    const { carrier, _links } = response.data.content;

    let mcNumber: string | null = null;

    if (_links['docket numbers']) {
      try {
        const docketResponse = await fmcsaAxios.get<DocketNumbersResponse>(
          `${_links['docket numbers'].href}?webKey=${Config.FMCSA_WEBKEY}`,
        );
        const mcDocket = docketResponse.data.content.find(
          (docket) => docket.prefix === 'MC',
        );
        if (mcDocket) {
          mcNumber = `MC-${mcDocket.docketNumber}`;
        }
      } catch (error) {
        console.error('Error fetching MC number:', error);
      }
    }

    const result = { carrier, mcNumber };
    usDotCache[usDotNumber] = result;
    return result;
  } catch (error) {
    console.error('Error validating USDOT:', error);
    return null;
  }
};
