import * as React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import debounce from 'lodash/debounce';
import * as service from './service';

type ZipCodeInfo = {
  city: string;
  state: string;
  country: string;
};

type InputZipCodePropsType = Omit<TextFieldProps, 'onChange'> & {
  value: string;
  onChange: (value: string) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  name: string;
  useGoogleMaps?: boolean;
  onZipCodeInfo?: (info: ZipCodeInfo | null) => void;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
  validateField?: (
    field: string,
  ) => Promise<void> | Promise<string | undefined>;
  onLoadingChange?: (isLoading: boolean) => void;
};

export function InputZipCode(props: InputZipCodePropsType) {
  const {
    value,
    onChange,
    onBlur,
    error,
    helperText,
    name,
    useGoogleMaps = false,
    onZipCodeInfo,
    setFieldTouched,
    validateField,
    onLoadingChange,
    ...textFieldProps
  } = props;

  const zipCodeCache = React.useRef<Record<string, ZipCodeInfo>>({});

  const formatZipCode = (input: string, previousValue: string): string => {
    const digits = input.replace(/\D/g, '');

    if (digits.length <= 5) return digits;

    if (input.length === 6 && input[5] === '-' && previousValue.length === 5) {
      return input;
    }

    return `${digits.slice(0, 5)}-${digits.slice(5, 9)}`;
  };

  const fetchZipCodeInfo = React.useCallback(
    debounce(async (zipCode: string) => {
      if (useGoogleMaps && zipCode.length === 5) {
        // Check if the ZIP code is already in the cache
        if (zipCodeCache.current[zipCode]) {
          if (onZipCodeInfo) {
            onZipCodeInfo(zipCodeCache.current[zipCode]);
          }
          return;
        }

        onLoadingChange?.(true);
        try {
          const info = await service.getZipCodeInfo(zipCode);
          // Store the fetched info in the cache
          zipCodeCache.current[zipCode] = info;
          if (onZipCodeInfo) {
            onZipCodeInfo(info);
          }
        } catch (error) {
          console.error('Error fetching ZIP code info:', error);
          if (onZipCodeInfo) {
            onZipCodeInfo(null);
          }
        } finally {
          onLoadingChange?.(false);
        }
      }
    }, 300),
    [useGoogleMaps, onZipCodeInfo, onLoadingChange],
  );

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    const formattedValue = formatZipCode(inputValue, value);
    onChange(formattedValue);

    fetchZipCodeInfo(formattedValue);
  };

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement>,
  ): Promise<void> => {
    onBlur(e);
    if (setFieldTouched) {
      setFieldTouched(name, true, false);
    }
    if (validateField) {
      await validateField(name);
    }
  };

  return (
    <TextField
      {...textFieldProps}
      name={name}
      value={value}
      onChange={handleZipChange}
      onBlur={handleBlur}
      error={error}
      helperText={helperText}
      inputProps={{
        ...textFieldProps.inputProps,
        maxLength: 10,
      }}
      placeholder="12345 or 12345-6789"
    />
  );
}
