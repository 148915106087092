import axios, { AxiosResponse } from 'axios';
import { Config } from '../config/env';
import { formatISO, startOfDay } from 'date-fns';

const apiUrl = Config.CARRIER_API_URL;

export const setLimitsService = async (
  values: any,
  carrierUuid: string,
): Promise<AxiosResponse> => {
  const parsedValues = {
    limits: [...values],
  };
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/limits_bulk`, parsedValues)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLimitsService = async (
  carrierUuid: string,
  requestDate: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}/limits?request_date=${requestDate}`)
    .then((response) => response);
};

export const setCarrierPromptsService = async (
  values: any,
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/prompts`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const getCarrierPromptsService = async (
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}/prompts`)
    .then((response) => response)
    .catch((error) => error);
};

export const addUserService = async (
  carrierUuid: string,
  values: any,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/users`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const getAllUsersService = async (
  carrierUuid: string,
  role: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}/users?roles=${role}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getCarrierTractors = async (
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}/units`)
    .then((response) => response)
    .catch((error) => error);
};

export const createCarrierTractor = async (
  carrierUuid: string,
  values: any,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/units`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const createCarrierTractorAssignments = async (
  carrierUuid: string,
  values: any,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/units/assignments`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const deactivateCarrierTractor = async (
  carrierUuid: string,
  uuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/units/${uuid}/deactivate`)
    .then((response) => response)
    .catch((error) => error);
};

export const activateCarrierTractor = async (
  carrierUuid: string,
  uuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}/units/${uuid}/activate`)
    .then((response) => response)
    .catch((error) => error);
};

export const updateCarrierTractor = async (
  carrierUuid: string,
  tractorUuid: string,
  values: any,
): Promise<AxiosResponse> => {
  return await axios
    .put(`${apiUrl}/carriers/${carrierUuid}/units/${tractorUuid}`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const getCarrier = async (
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}`)
    .then((response) => response);
};

export const getBalanceService = async (
  driverUuid: string,
): Promise<AxiosResponse> => {
  const today = new Date();
  const midnightTonight = startOfDay(today); // Set time to midnight
  const formattedDate = formatISO(midnightTonight);

  return await axios
    .get(
      `${apiUrl}/drivers/${driverUuid}/balance?start_date_time=${formattedDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export const updateCarrierService = async (
  values: any,
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/carriers/${carrierUuid}`, values)
    .then((response) => response)
    .catch((error) => error);
};
