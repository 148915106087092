import React from 'react';
import { Typography, Grid } from '@mui/material';

export function Denied() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Typography variant="h3">403 - access denied</Typography>
    </Grid>
  );
}
