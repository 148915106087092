import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import * as Yup from 'yup';
import {
  boxStyle,
  cancelStyle,
  submitStyle,
} from '@/fleet/components/common/styles';
import { Formik } from 'formik';
import { createCarrierTractorAssignments } from '@/fleet/services/carriers';
import Papa from 'papaparse';

interface AddTractorModalProps {
  handleClose: () => void;
  carrierUuid: string | undefined;
  setShowSuccessAlert: (bool: boolean) => void;
  setShowErrorAlert: (bool: boolean) => void;
}

type FailedAssignmentType = {
  driverId: string;
  unitId: string;
};

const validationSchemaObject = Yup.object().shape({
  csvFile: Yup.mixed().required('CSV file is required'),
});

const initialValues = {
  csvFile: null,
  tractors: [],
};

const ImportTractorAssignmentModal = ({
  handleClose,
  carrierUuid,
  setShowSuccessAlert,
  setShowErrorAlert,
}: AddTractorModalProps) => {
  const [fileName, setFileName] = useState('');
  const [failedAssignments, setFailedAssignments] = useState<
    FailedAssignmentType[]
  >([]);

  const handleCSVUpload = (file: File, setFieldValue: any) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const tractors = results.data.map((row: any) => ({
          driver_id: row.driverExternalId,
          unit_id: row.tractorExternalId,
        }));
        setFieldValue('tractors', tractors);
        setFileName(file.name);
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error);
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={(values) => {
        if (!carrierUuid) {
          setShowErrorAlert(true);
          return;
        }

        const assignments = values.tractors.map((tractor: any) => ({
          driver_id: tractor.driver_id,
          unit_id: tractor.unit_id,
        }));

        const requestPayload = {
          carrier_uuid: carrierUuid,
          assignments,
        };

        createCarrierTractorAssignments(carrierUuid, requestPayload).then(
          (response: any) => {
            if (response.status === 200) {
              if (response.data.failedAssignments?.length > 0) {
                setFailedAssignments(response.data.failedAssignments);
                setShowErrorAlert(true);
              } else {
                setShowSuccessAlert(true);
                handleClose();
              }
            } else {
              setShowErrorAlert(true);
              handleClose();
            }
          },
        );
      }}
    >
      {({ errors, handleSubmit, setFieldValue, touched, resetForm }: any) => (
        <>
          <Modal
            open
            onClose={() => {
              handleClose();
              setFailedAssignments([]);
              resetForm();
            }}
            aria-labelledby="modal-title"
          >
            <form onSubmit={handleSubmit}>
              <Box sx={boxStyle}>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h1"
                  sx={{ mb: 2, mt: 1 }}
                >
                  Import Tractors
                </Typography>
                <Box mb={2}>
                  <FormControl fullWidth>
                    <input
                      id="csvFile"
                      name="csvFile"
                      type="file"
                      accept=".csv"
                      onChange={(event) => {
                        if (
                          event.currentTarget.files &&
                          event.currentTarget.files[0]
                        ) {
                          setFieldValue(
                            'csvFile',
                            event.currentTarget.files[0],
                          );
                          handleCSVUpload(
                            event.currentTarget.files[0],
                            setFieldValue,
                          );
                          setFailedAssignments([]); // Clear previous errors
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="csvFile">
                      <Button variant="contained" component="span" fullWidth>
                        Upload CSV
                      </Button>
                    </label>
                    {errors.csvFile && touched.csvFile && (
                      <FormHelperText error>{errors.csvFile}</FormHelperText>
                    )}
                    {fileName && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ mt: 1 }}
                      >
                        File selected: {fileName}
                      </Typography>
                    )}
                  </FormControl>
                  {failedAssignments.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="body2" color="error">
                        The following assignments failed:
                      </Typography>
                      <Table size="small" aria-label="failed assignments">
                        <TableHead>
                          <TableRow>
                            <TableCell>Driver ID</TableCell>
                            <TableCell>Unit ID</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {failedAssignments.map((assignment, index) => (
                            <TableRow key={index}>
                              <TableCell>{assignment.driverId}</TableCell>
                              <TableCell>{assignment.unitId}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  )}
                </Box>
                <Box display="flex" mt={3} justifyContent="flex-end">
                  <Button
                    sx={cancelStyle}
                    onClick={() => {
                      handleClose();
                      setFailedAssignments([]);
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    sx={submitStyle}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        </>
      )}
    </Formik>
  );
};

export default ImportTractorAssignmentModal;
