import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { format } from 'date-fns';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import { addDriverService, setPasswordService } from '@/fleet/services/drivers';
import { Mixpanel } from '@/fleet/config/mixpanel';

const boxStyle = {
  maxWidth: '100vw',
  maxHeight: '100%',
  position: 'fixed',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
  m: 1,
  borderRadius: 2,
};

const cancelStyle = {
  height: 40,
  textTransform: 'none',
  color: '#181818',
  padding: '15px',
};

const submitStyle = {
  background: '#181818',
  height: 40,
  textTransform: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#353535',
  },
  padding: '15px',
};

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const validationSchemaObject = Yup.object().shape({
  driverExternalId: Yup.string().required('Required field'),
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email().required('Required field'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .required('Required field'),
  password: Yup.string().required('Required field'),
  type: Yup.string().required('Required field'),
  startDate: Yup.date().nullable().required('Required field'),
});

const initialValues = {
  driverExternalId: '',
  carrierUuid: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  type: '',
  startDate: null,
  uuid: '',
  carrierName: '',
  password: '',
};

const FORM_INPUT_FIELDS = [
  {
    value: 'driverExternalId',
    label: 'Driver External ID',
  },
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

const AddDriverModal = ({
  handleClose,
  setShowSuccessAlert,
  setShowErrorAlert,
  setAlertMessage,
}: any) => {
  const [, setLoading] = useState<boolean>(false);

  const { carrierUuid } = useParams();

  const addDriver = async (values: any) => {
    const startStr =
      values.startDate !== null ? format(values.startDate, 'yyyyMMdd') : '';

    const payloadValues = {
      ...values,
      startDate: startStr,
      carrierUuid,
    };

    addDriverService(payloadValues)
      .then((response) => {
        handleClose();
        if (response.status === 200) {
          const passwordValues = {
            password: values.password,
            temporary: true,
            entityUuid: response.data.driver.uuid,
            identityUuid: response.data.driver.identityUuid,
          };
          setPasswordService(passwordValues)
            .then(() => {})
            .catch(() => {});
          setShowSuccessAlert(true);
          Mixpanel.track('driver_added', {
            driverExternalId: values.driverExternalId,
            startDate: values.startDate,
            type: values.type,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
          });
        } else {
          setAlertMessage(response.statusText);
          setShowErrorAlert(true);
        }
      })
      .catch(() => {
        setShowErrorAlert(true);
      });

    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={(values) => {
        setLoading(true);
        addDriver(values);
      }}
    >
      {/* TODO: fix these types */}
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        touched,
      }: any) => (
        <Modal open onClose={handleClose} aria-labelledby="modal-title">
          <form onSubmit={handleSubmit}>
            <Box sx={boxStyle}>
              <Typography
                id="modal-title"
                variant="h6"
                component="h1"
                sx={{ mb: 2, mt: 1 }}
              >
                Add Driver
              </Typography>
              {FORM_INPUT_FIELDS.map((field) => {
                const { value, label } = field;
                if (value === 'phoneNumber') {
                  return (
                    <InputMask
                      key={field.label}
                      mask="(999) 999-9999"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {() => {
                        return (
                          <TextField
                            key={`${value}-${label}`}
                            id={value}
                            label={label}
                            variant="outlined"
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            error={touched[value] && !!errors[value]}
                            helperText={touched[value] && errors[value]}
                          />
                        );
                      }}
                    </InputMask>
                  );
                }
                return (
                  <TextField
                    key={`${value}-${label}`}
                    id={value}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                    error={touched[value] && !!errors[value]}
                    helperText={touched[value] && errors[value]}
                  />
                );
              })}
              <Box mb={1}>
                <FormControl fullWidth>
                  <InputLabel id="type" error={touched.type && !!errors.type}>
                    Driver Type
                  </InputLabel>
                  <Select
                    labelId="driver type label"
                    name="type"
                    value={values.type}
                    label="Driver Type"
                    onChange={handleChange}
                    error={touched.type && !!errors.type}
                  >
                    <MenuItem value="Owner Operator">Owner Operator</MenuItem>
                    <MenuItem value="Company">Company</MenuItem>
                  </Select>
                  <FormHelperText error={touched.type && !!errors.type}>
                    {touched.type && errors.type}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box mb={1}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.startDate && !!errors.startDate}
                          helperText={touched.startDate && errors.startDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box display="flex" mt={3} justifyContent="flex-end">
                <Button sx={cancelStyle} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  sx={submitStyle}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddDriverModal;
