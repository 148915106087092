import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Paper,
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ErrorAlert from '@/fleet/components/common/alerts/error';
import SuccessAlert from '@/fleet/components/common/alerts/success';
import {
  cancelButtonStyle,
  submitButtonStyle,
} from '@/fleet/components/common/styles';
import {
  activateCarrierTractor,
  createCarrierTractor,
  deactivateCarrierTractor,
  getCarrierTractors,
} from '@/fleet/services/carriers';
import { listDriversService } from '@/fleet/services/drivers';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import { StatusPill } from '@/fleet/components/common/StatusPill';
import { Eligibility } from './Tractors';
import {
  createSubscriptions,
  cancelSubscriptions,
} from '@/fleet/services/metrics';
import SubscriptionLoadingBar from './SubscriptionLoadingBar';

type TractorProps = {
  carrierUuid: string;
  driverUuid: string;
  endDate: string;
  startDate: string;
  unitId: string;
  uuid: string;
  vin: string;
};

export enum TelematicsAction {
  NoOp = 'no-op',
  Subscribe = 'subscribe',
  Cancel = 'cancel',
}

const initialValues = {
  unitId: '',
  vin: '',
  driverUuid: '',
  startDate: null,
};

const validationSchemaObject = Yup.object().shape({
  unitId: Yup.string().required('Required field'),
  vin: Yup.string().required('Required field'),
  driverUuid: Yup.string().optional(),
  startDate: Yup.date().nullable().required('Required field'),
});

export function Tractor() {
  const { state } = useLocation() as {
    state: {
      tractorData: TractorProps[];
      tractor: TractorProps;
      eligibility: Eligibility;
    };
  };
  const [loading, setLoading] = useState(true);
  const [showConfirmStatusChange, setShowConfirmStatusChange] = useState(false);
  const [tractor, setTractor] = useState(initialValues);
  const [assignableDrivers, setAssignableDrivers] = useState<any[]>();
  const [allDrivers, setAllDrivers] = useState<any>();

  const [message, setMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [tractorActive, setTractorActive] = useState(false);
  const [editingTractor, setEditingTractor] = useState(false);
  const [subscriptionPresent, setSubcriptionPresent] = useState<boolean>(false);
  const [telematicsAction, setTelematicsAction] = useState<TelematicsAction>(
    TelematicsAction.NoOp,
  );
  const [creatingSubscription, setCreatingSubscription] =
    useState<boolean>(false);
  const [cancelingSubscription, setCancelingSubscription] =
    useState<boolean>(false);

  const { tractorUuid, carrierUuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let drivers: any[] = [];
    const getDrivers = async () => {
      if (carrierUuid) {
        await listDriversService(carrierUuid).then((response) => {
          drivers = (response as AxiosResponse<any>).data?.drivers;

          setAllDrivers(drivers);

          if (drivers) {
            const driverUuidsInUse = state.tractorData.map(
              (t: any) => t.driverUuid,
            );

            // Filter drivers that are not assigned to any tractor
            const filteredDrivers = drivers.filter(
              (driver) => !driverUuidsInUse.includes(driver.uuid),
            );

            setAssignableDrivers(filteredDrivers);
          }
        });
      }
      if (state.eligibility) {
        resolveTelematicsAction();
      }
      setLoading(false);
    };
    getDrivers();
  }, [carrierUuid]);

  const handleChangeStatus = () => {
    if (carrierUuid) {
      if (tractorActive) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TODO: Resolve type discrepancy and access the correct property
        deactivateCarrierTractor(carrierUuid, tractor.uuid)
          .then((response) => {
            if (response.status === 200) {
              setOpenSuccess(true);
              setMessage('Tractor deactivated successfully');
              setTractorActive(false);
            } else {
              setOpenError(true);
              setMessage(
                'There was an issue deactivating the tractor, contact support.',
              );
            }
          })
          .catch(() => {
            setOpenError(true);
            setMessage(
              'There was an issue deactivating the tractor, contact support.',
            );
          });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TODO: Resolve type discrepancy and access the correct property
        activateCarrierTractor(carrierUuid, tractor.uuid)
          .then((response) => {
            if (response.status === 200) {
              setOpenSuccess(true);
              setMessage('Tractor activated successfully');
              setTractorActive(true);
            } else {
              setOpenError(true);
              setMessage(
                'There was an issue activating the tractor, contact support.',
              );
            }
          })
          .catch(() => {
            setOpenError(true);
            setMessage(
              'There was an issue activating the tractor, contact support.',
            );
          });
      }
      setShowConfirmStatusChange(false);
    }
  };

  const resolveLoadingComponent = () => {
    if (creatingSubscription || cancelingSubscription) {
      return <SubscriptionLoadingBar creating={creatingSubscription} />;
    }

    return (
      <CircularProgress
        sx={{
          color: '#181818',
        }}
      />
    );
  };

  const getAssignedDriverName = (driverUuid: string) => {
    const driver = allDrivers?.find((d: any) => d.uuid === driverUuid);

    if (!driver) {
      return;
    }

    if (driver) {
      return `${driver.firstName} ${driver.lastName}`;
    }
  };

  const resolveTelematicsAction = () => {
    if (state.eligibility.ineligibilityReasons) {
      const present = state.eligibility.ineligibilityReasons.some(
        (r: string) => r === 'Subscription Already Present',
      );
      setSubcriptionPresent(present);
      if (present) {
        setTelematicsAction(TelematicsAction.Subscribe);
      } else {
        setTelematicsAction(TelematicsAction.Cancel);
      }
    } else {
      setTelematicsAction(TelematicsAction.Cancel);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (carrierUuid) {
      getCarrierTractors(carrierUuid).then((response) => {
        const data = response.data?.tractorUnits.filter(
          (t: any) => t.uuid === tractorUuid,
        )[0];

        setTractor(data);
        setTractorActive(!data.endDate);
      });

      if (state.eligibility) {
        resolveTelematicsAction();
      }
    }

    setLoading(false);
  }, [carrierUuid]);

  return (
    <Paper square elevation={1} sx={{ p: 3 }}>
      <Box>
        <Box>
          <Button
            size="small"
            variant="text"
            sx={{ color: '#000', backgroundColor: '#fff' }}
            onClick={() => navigate(`/carriers/${carrierUuid}/tractors`)}
          >
            <ArrowBackIcon />
            <Typography variant="h6" sx={{ textTransform: 'none', ml: 1 }}>
              Tractors
            </Typography>
          </Button>
        </Box>

        <br />
        {loading ? (
          resolveLoadingComponent()
        ) : (
          <Formik
            enableReinitialize
            initialValues={tractor}
            validationSchema={validationSchemaObject}
            onSubmit={(values) => {
              setLoading(true);

              createCarrierTractor(carrierUuid!, values)
                .then(() => {
                  toast.success('successfully saved tractor data');
                  setTractor(values);

                  if (telematicsAction === TelematicsAction.Subscribe) {
                    toast.loading('creating telematics subscription...');
                  }

                  if (telematicsAction === TelematicsAction.Cancel) {
                    toast.loading('cancelling telematics subscription...');
                  }

                  switch (telematicsAction) {
                    case TelematicsAction.Subscribe:
                      setCreatingSubscription(true);
                      createSubscriptions([values.vin]).then((resp) => {
                        const subscribedVehicles = (resp as AxiosResponse).data
                          .subscribedVehicles;
                        if (subscribedVehicles.length > 0) {
                          toast.success(
                            'successfully created telematics subscription',
                          );
                          state.eligibility.eligible = false;
                          state.eligibility.ineligibilityReasons = [
                            'Subscription Already Present',
                          ];
                        } else {
                          const ineligible = (
                            resp as AxiosResponse
                          ).data.ineligibleVehicles.find(
                            (vehicle: { vin: string; errors: string[] }) =>
                              vehicle.vin === values.vin,
                          );
                          toast.error(
                            `failed to create telematics subscription: ${ineligible.errors}`,
                          );
                        }
                        setCreatingSubscription(false);
                        setLoading(false);
                        setEditingTractor(false);
                      });
                      toast.dismiss();
                      break;
                    case TelematicsAction.Cancel:
                      setCancelingSubscription(true);
                      cancelSubscriptions([values.vin]).then((resp) => {
                        const canceledSubscriptions = (resp as AxiosResponse)
                          .data.canceledSubscriptions;
                        if (canceledSubscriptions.length > 0) {
                          toast.success(
                            'successfully canceled telematics subscription',
                          );
                          state.eligibility.eligible = true;
                          state.eligibility.ineligibilityReasons = [];
                        } else {
                          const ineligible = (
                            resp as AxiosResponse
                          ).data.ineligibleVehicles.find(
                            (vehicle: { vin: string; errors: string[] }) =>
                              vehicle.vin === values.vin,
                          );
                          toast.error(
                            `failed to cancel telematics subscription: ${ineligible.errors}`,
                          );
                        }
                        setCancelingSubscription(false);
                        setLoading(false);
                        setEditingTractor(false);
                      });
                      toast.dismiss();
                      break;
                    case TelematicsAction.NoOp:
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  setEditingTractor(false);
                  toast.error(error);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              resetForm,
            }) => (
              <Box m={1} mt={2} width="50%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                  border={1}
                  borderColor="#D4D4D4"
                  bgcolor="#F8F8FA"
                  fontWeight={600}
                  fontSize={14}
                  style={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Box ml={1}>
                    {tractorActive ? (
                      <Button
                        variant="outlined"
                        sx={{
                          width: 200,
                          textTransform: 'none',
                          color: '#D93026',
                          borderColor: '#D93026',
                          '&:hover': {
                            backgroundColor: '#ffe6e6',
                            borderColor: '#D93026',
                          },
                        }}
                        onClick={() => setShowConfirmStatusChange(true)}
                      >
                        Deactivate Tractor
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{
                          width: 200,
                          textTransform: 'none',
                          color: '#181818',
                          borderColor: '#181818',
                          '&:hover': {
                            backgroundColor: '#cccccc',
                            borderColor: '#181818',
                          },
                        }}
                        onClick={() => setShowConfirmStatusChange(true)}
                      >
                        Activate Tractor
                      </Button>
                    )}
                  </Box>
                  <Box>
                    {editingTractor && (
                      <Button
                        sx={{
                          width: 75,
                          paddingX: 3,
                          paddingY: 1,
                          mr: 1,
                          bgcolor: 'black',
                          color: 'white',
                          borderRadius: 1,
                          '&:hover': {
                            backgroundColor: 'gray',
                          },
                        }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      sx={{
                        width: 75,
                        paddingX: 3,
                        paddingY: 1,
                        border: 1,
                        borderColor: editingTractor ? 'gray' : null,
                        bgcolor: editingTractor ? 'white' : 'black',
                        color: editingTractor ? 'black' : 'white',
                        borderRadius: 1,
                        '&:hover': {
                          backgroundColor: 'gray',
                        },
                      }}
                      onClick={() => {
                        if (editingTractor) {
                          setEditingTractor(false);
                          resetForm({ values: tractor });
                        } else {
                          setEditingTractor(true);
                        }
                      }}
                    >
                      {editingTractor ? 'Cancel' : 'Edit'}
                    </Button>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                  px={4}
                  fontSize={14}
                  border={1}
                  borderTop={0}
                  borderColor="#D4D4D4"
                >
                  <Box width="50%">VIN #:</Box>
                  <Box width="50%">
                    <TextField
                      disabled={!editingTractor}
                      id="vin"
                      value={values.vin}
                      name="vin"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      sx={{ marginBottom: 2 }}
                      error={touched.vin && !!errors.vin}
                      helperText={touched.vin && errors.vin}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                  px={4}
                  fontSize={14}
                  border={1}
                  borderTop={0}
                  borderColor="#D4D4D4"
                >
                  <Box width="50%">Unit ID:</Box>
                  <Box width="50%">
                    <TextField
                      disabled={!editingTractor}
                      id="unitId"
                      value={values.unitId}
                      name="unitId"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      sx={{ marginBottom: 2 }}
                      error={touched.unitId && !!errors.unitId}
                      helperText={touched.unitId && errors.unitId}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                  px={4}
                  fontSize={14}
                  border={1}
                  borderTop={0}
                  borderColor="#D4D4D4"
                  sx={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  <Box width="50%">Assigned Driver:</Box>
                  {!editingTractor ? (
                    <InputLabel sx={{ py: 3 }}>
                      {values.driverUuid ? (
                        getAssignedDriverName(values.driverUuid)
                      ) : (
                        <StatusPill active={false} text="Unassigned" />
                      )}
                    </InputLabel>
                  ) : (
                    <Select
                      name="driverUuid"
                      value={values.driverUuid}
                      onChange={handleChange}
                      disabled={isEmpty(assignableDrivers)}
                      error={touched.driverUuid && !!errors.driverUuid}
                      sx={{
                        width: '50%',
                        my: 1,
                      }}
                    >
                      {values.driverUuid ? (
                        <MenuItem value={values.driverUuid}>
                          {getAssignedDriverName(values.driverUuid)}
                        </MenuItem>
                      ) : null}
                      {assignableDrivers?.map((driver) => {
                        return (
                          <MenuItem value={driver.uuid} key={driver.uuid}>
                            {driver.firstName} {driver.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Box>
                {state.eligibility ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    py={2}
                    px={4}
                    fontSize={14}
                    border={1}
                    borderTop={0}
                    borderColor="#D4D4D4"
                    sx={{
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <Box width="50%">Telematics Integration:</Box>
                    <FormControl>
                      <RadioGroup row name="telematicsAction">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="left"
                        >
                          <Tooltip
                            title={
                              subscriptionPresent
                                ? 'cancel subscription'
                                : 'no subscription'
                            }
                          >
                            <>
                              <Box>None</Box>
                              <Radio
                                sx={{ marginLeft: '10%' }}
                                checked={
                                  telematicsAction === TelematicsAction.Cancel
                                }
                                onChange={() =>
                                  setTelematicsAction(TelematicsAction.Cancel)
                                }
                                value={TelematicsAction.Cancel}
                                name="cancel"
                                disabled={!editingTractor}
                              />
                            </>
                          </Tooltip>
                          <Tooltip
                            title={
                              state.eligibility.ineligibilityReasons &&
                              state.eligibility.ineligibilityReasons.length > 0
                                ? state.eligibility.ineligibilityReasons.join(
                                    ',',
                                  )
                                : 'subscribe'
                            }
                          >
                            <>
                              <Box ml={10}>DTNA</Box>
                              <Radio
                                checked={
                                  telematicsAction ===
                                  TelematicsAction.Subscribe
                                }
                                onChange={() =>
                                  setTelematicsAction(
                                    TelematicsAction.Subscribe,
                                  )
                                }
                                value={TelematicsAction.Subscribe}
                                name="subscribe"
                                disabled={
                                  !editingTractor ||
                                  subscriptionPresent ||
                                  !state.eligibility.eligible
                                }
                              />
                            </>
                          </Tooltip>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ) : null}
              </Box>
            )}
          </Formik>
        )}
        <Dialog
          open={showConfirmStatusChange}
          onClose={() => setShowConfirmStatusChange(false)}
          aria-labelledby="modal-title"
        >
          <Box m={2}>
            <Typography mb={2} variant="h6">{`Are you sure you want to ${
              tractorActive ? 'deactivate' : 'activate'
            } tractor?`}</Typography>
            <Box>
              <Button
                variant="outlined"
                sx={[cancelButtonStyle, { width: 200, marginRight: 1 }]}
                onClick={() => setShowConfirmStatusChange(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={[submitButtonStyle, { width: 200 }]}
                onClick={handleChangeStatus}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Dialog>
        <SuccessAlert
          message={message}
          open={openSuccess}
          handleClose={() => setOpenSuccess(false)}
        />
        <ErrorAlert
          message={message}
          open={openError}
          handleClose={() => setOpenError(false)}
        />
      </Box>
    </Paper>
  );
}
