import axios, { AxiosResponse } from 'axios';
import { Config } from '@/fleet/config/env';
import { CombinedFormValuesType } from './';

const apiUrl = Config.CARRIER_API_URL;

// Define the mapping between frontend and backend fields
const fieldMapping: Record<
  string,
  | keyof CombinedFormValuesType
  | [keyof CombinedFormValuesType, keyof CombinedFormValuesType]
> = {
  business_name: 'businessName',
  business_email: 'email',
  business_address1: 'businessAddress1',
  business_address2: 'businessAddress2',
  business_city: 'businessCity',
  business_region: 'businessState',
  business_zip_code: 'businessZip',
  business_country: 'businessCountry',
  business_phone_number: 'businessPhoneNumber',
  business_ein: 'federalIdNumber',
  number_of_drivers: 'numberOfTrucks',
  department_of_transportation_number: 'usDotNumber',
  estimated_weekly_spent: 'weeklyFuelSpend',
  owner_legal_name: ['owner1FirstName', 'owner1LastName'],
  owner_ssn: 'owner1SSN',
  owner_date_of_birth: 'owner1DateOfBirth',
  owner_address1: 'owner1AddressLine1',
  owner_address2: 'owner1AddressLine2',
  owner_city: 'owner1City',
  owner_region: 'owner1State',
  owner_zip_code: 'owner1Zip',
  owner_country: 'owner1Country',
  primary_contact_email: 'owner1Email',
  primary_contact_name: ['owner1FirstName', 'owner1LastName'],
  primary_contact_phone_number: 'owner1MobilePhoneNumber',
};

// Function to transform frontend data to backend format
function transformData(
  frontendData: CombinedFormValuesType,
): Record<string, any> {
  const backendData: Record<string, any> = {};

  for (const [backendField, frontendField] of Object.entries(fieldMapping)) {
    if (Array.isArray(frontendField)) {
      backendData[backendField] = frontendField
        .map((f) => frontendData[f])
        .filter(Boolean)
        .join(' ');
    } else if (frontendField in frontendData) {
      backendData[backendField] = frontendData[frontendField];
    }
  }

  return backendData;
}

export const createCreditApplicationService = async (
  payload: CombinedFormValuesType,
): Promise<AxiosResponse> => {
  const transformedData = transformData(payload);
  try {
    const response = await axios.post(
      `${apiUrl}/applications/credit`,
      transformedData,
    );
    return response;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};
