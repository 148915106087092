import * as React from 'react';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { Outlet, useLoaderData, redirect } from 'react-router-dom';
import { Mixpanel, initMixpanel } from '@/fleet/config/mixpanel';
import PageLayout from '@/fleet/components/PageLayout';
import { PathPublic } from '@/fleet/paths/constants';
import AuthService from '@/fleet/services/auth';

export async function loader(options: LoaderFunctionArgs) {
  const { params } = options;
  const { carrierUuid } = params;

  if (!AuthService.isLoggedIn()) {
    return redirect(PathPublic.Login);
  }

  const entityUuid = AuthService.getCarrierUuid();
  const uuid = carrierUuid || entityUuid;

  if (!uuid) {
    return redirect(PathPublic.NotFound);
  }

  const hasRolePermission =
    AuthService.hasRole(['fleet_manager']) || AuthService.isStaffUser();

  if (hasRolePermission) {
    return { uuid };
  }

  return redirect(PathPublic.Denied);
}

export function LayoutPrivate() {
  const { uuid } = useLoaderData() as { uuid: string };

  React.useEffect(() => {
    initMixpanel();
    Mixpanel.identify(AuthService.getUserEmail());
  }, []);

  return (
    <PageLayout carrierUuid={uuid}>
      <Outlet />
    </PageLayout>
  );
}
