export const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 130,
    my: 2,
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    pb: 2,
  },
  title: {
    fontWeight: 600,
    my: 2,
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  textCenter: {
    textAlign: 'center',
    color: '#666',
  },
  value: {
    fontSize: 26,
    fontWeight: 700,
    textAlign: 'center',
  },
};
