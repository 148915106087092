import AuthService from '@/fleet/services/auth';
import { PathPrivate } from './constants';

interface Values {
  [key: string]: string; // This says every string key in this object will have a string value
}

const replaceParams = (template: string, values: Values) => {
  return template.replace(/:\w+/g, (match) => {
    // Remove the colon and get the property name
    const key = match.substring(1);
    return values[key] || match;
  });
};

function getPath(template: string, uuid?: string) {
  const options = { carrierUuid: uuid || AuthService.getCarrierUuid() };
  return replaceParams(template, options);
}

export function getPathDashboard(uuid?: string) {
  return getPath(PathPrivate.Dashboard, uuid);
}

export function getPathDrivers(uuid?: string) {
  return getPath(PathPrivate.Drivers, uuid);
}

export function getPathStations(uuid?: string) {
  return getPath(PathPrivate.Stations, uuid);
}

export function getPathMembers(uuid?: string) {
  return getPath(PathPrivate.Members, uuid);
}

export function getPathTractors(uuid?: string) {
  return getPath(PathPrivate.Tractors, uuid);
}

export function getPathSettings(uuid?: string) {
  return getPath(PathPrivate.Settings, uuid);
}

export function getPathReports(uuid?: string) {
  return getPath(PathPrivate.Reports, uuid);
}

export function getPathOneTimeLimit(uuid?: string) {
  return getPath(PathPrivate.OneTimeLimit, uuid);
}
