import React, { useState } from 'react';
import { format, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import { TimePicker } from '@mui/x-date-pickers';
import {
  listCarrierTransactionsService,
  listFuelTaxReport,
} from '@/fleet/services/transactions';
import { ExportDataAsCSV } from '@/fleet/components/helpers/csv/exporter';
import { Mixpanel } from '@/fleet/config/mixpanel';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import useGetDrivers, { IDriver } from '@/fleet/hooks/useGetDrivers';
import { uniq } from 'lodash';

const boxStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 2,
  m: 1,
};

const closeStyle = {
  height: 40,
  textTransform: 'none',
  color: '#181818',
  padding: '15px',
};

const submitStyle = {
  background: '#181818',
  height: 40,
  textTransform: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#353535',
  },
  padding: '15px',
};

const dateRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginY: 1,
  paddingX: 1,
  paddingY: 0.5,
};

const REPORT_TYPES = {
  TRANSACTIONS: 'transactions',
  FUEL_TAX: 'fuel_tax',
};

type ExportTransactionsModalProps = {
  handleClose: () => void;
  availableUnitNumbers: string[];
};

const ExportTransactionModal = ({
  handleClose,
  availableUnitNumbers,
}: ExportTransactionsModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [driverUuid, setDriverUuid] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [exportReportType, setExportReportType] = useState<string | null>(null);
  const { carrierUuid } = useParams();
  const { drivers } = useGetDrivers(carrierUuid);

  const defaultDriverAutocompleteProps = {
    options: drivers,
    getOptionLabel: (option: IDriver) =>
      `${option.firstName} ${option.lastName}`,
  };

  const defaultUnitNumberAutocompleteProps = {
    options: uniq(availableUnitNumbers),
    getOptionLabel: (option: string) => option,
  };

  const runReport = (cuuid: string, sDate: string, eDate: string) => {
    switch (exportReportType) {
      case REPORT_TYPES.TRANSACTIONS:
        return listCarrierTransactionsService(
          cuuid,
          sDate,
          eDate,
          driverUuid,
          unitNumber,
        );
      case REPORT_TYPES.FUEL_TAX:
        return listFuelTaxReport(cuuid, sDate, eDate);
      default:
        return listCarrierTransactionsService(cuuid, sDate, eDate);
    }
  };

  const exportTransactions = async () => {
    const startStr = startDate !== null ? format(startDate, 'MM-dd-yyyy') : '';
    const endStr = endDate !== null ? format(endDate, 'MM-dd-yyyy') : '';

    const sDate = startDate !== null ? startDate.toISOString() : '';
    const eDate = endDate !== null ? endDate.toISOString() : '';
    const cuuid = carrierUuid !== undefined ? carrierUuid.toString() : '';

    runReport(cuuid, sDate, eDate)
      .then((response) => {
        let data: { name: string; label: string }[] = [];

        if (exportReportType === REPORT_TYPES.FUEL_TAX) {
          data = [
            { name: 'date', label: 'Date' },
            { name: 'time', label: 'Time' },
            { name: 'state', label: 'State' },
            { name: 'productName', label: 'Product Name' },
            { name: 'productCode', label: 'Product Code' },
            { name: 'quantity', label: 'Quantity' },
            { name: 'unitNumber', label: 'Unit Number' },
          ];
        } else if (exportReportType === REPORT_TYPES.TRANSACTIONS) {
          data = [
            { name: 'date', label: 'Date' },
            { name: 'time', label: 'Time' },
            { name: 'transactionNumber', label: 'Transaction Number' },
            { name: 'merchantId', label: 'Merchant Id' },
            { name: 'truckStopName', label: 'Truckstop Name' },
            { name: 'storeNumber', label: 'Store Number' },
            { name: 'city', label: 'City' },
            { name: 'region', label: 'Region' },
            { name: 'productName', label: 'Product Name' },
            { name: 'productCode', label: 'Product Code' },
            { name: 'quantity.value', label: 'Quantity' },
            { name: 'unitRetailCost.value', label: 'Unit Retail Cost' },
            {
              name: 'unitDiscountedCost.value',
              label: 'Unit Discounted Cost',
            },
            { name: 'fee', label: 'Fee Amount' },
            {
              name: 'productRetailTotal.value',
              label: 'Product Retail Cost',
            },
            {
              name: 'productDiscountedTotal.value',
              label: 'Product Discounted Total',
            },
            {
              name: 'discountTotal.value',
              label: 'Discount Total',
            },
            {
              name: 'grandTotal',
              label: 'Grand Total',
            },
            { name: 'unitNumber', label: 'Unit Number' },
            { name: 'trailerNumber', label: 'Trailer Number' },
            { name: 'driverNumber', label: 'Driver Number' },
            { name: 'driverName', label: 'Driver Name' },
            { name: 'authorizationNumber', label: 'Auth Number' },
            { name: 'inNetworkIndicator', label: 'In Network Indicator' },
            { name: 'division', label: 'Division' },
            { name: 'tripNumber', label: 'Trip Number' },
            { name: 'odometer', label: 'Odometer' },
            { name: 'pumpNumber', label: 'Pump Number' },
          ];
        }
        if (response.data) {
          ExportDataAsCSV({
            data: response.data.transactionRecords,
            columns: data,
            fileName: `${startStr}-${endStr}-${exportReportType}-report.csv`,
            fromJson: true,
          });
          setLoading(false);
          Mixpanel.track(`${exportReportType}_exported`);
          handleClose();
        }
      })
      .catch((e: any) => {
        alert(e);
      });
  };

  return (
    <Modal open onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={boxStyle}>
        <Typography id="modal-title" variant="h6" component="h1" sx={{ p: 0 }}>
          Export Report
        </Typography>
        <Box mt={2}>
          <Box px={1} py={0.5}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
              <Select
                value={exportReportType}
                label="Report Type"
                onChange={(e) => setExportReportType(e.target.value)}
              >
                <MenuItem value={REPORT_TYPES.TRANSACTIONS}>
                  Transactions
                </MenuItem>
                <MenuItem value={REPORT_TYPES.FUEL_TAX}>Fuel Tax</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={dateRowStyle}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <TimePicker
                label="Start Time"
                value={startDate}
                onChange={(newValue) => {
                  isValid(newValue) && setStartDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Box>
            <Box sx={dateRowStyle}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <TimePicker
                label="End Time"
                value={endDate}
                onChange={(newValue) => {
                  isValid(newValue) && setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Box>
          </LocalizationProvider>
          {drivers && exportReportType === 'transactions' ? (
            <>
              <Box px={1}>
                <Autocomplete
                  {...defaultDriverAutocompleteProps}
                  options={drivers}
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e: any, driver: any) => {
                    if (driver) {
                      setDriverUuid(driver.uuid);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Driver (optional)" />
                  )}
                  disabled={exportReportType !== 'transactions'}
                />
              </Box>
              <Box mt={1} px={1}>
                <Autocomplete
                  {...defaultUnitNumberAutocompleteProps}
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e: any, unitNumber: string | null) => {
                    if (unitNumber) {
                      setUnitNumber(unitNumber);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Unit Number (optional)" />
                  )}
                  disabled={exportReportType !== 'transactions'}
                />
              </Box>
            </>
          ) : null}
        </Box>
        <Box display="flex" m={1} justifyContent="flex-end">
          <Button sx={closeStyle} onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            size="small"
            color="secondary"
            sx={submitStyle}
            onClick={() => {
              setLoading(true);
              exportTransactions();
            }}
            loading={loading}
            loadingPosition="start"
            startIcon={<DownloadIcon />}
            disabled={Boolean(!startDate || !endDate) || !exportReportType}
            variant="contained"
          >
            Export
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportTransactionModal;
