import React from 'react';
import { Box } from '@mui/material';

const MEMBER_ROLES = {
  FLEET_MANAGER: 'Fleet Manager',
  DRIVER: 'Driver',
  STAFF: 'Staff',
  PROGRAM: 'Program',
  UNDEFINED: null,
};

const MemberRow = ({ member, isLastRow }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 4,
        py: 2,
        borderBottom: !isLastRow ? 1 : null,
        borderColor: '#D4D4D4',
      }}
    >
      <Box>
        <Box>
          {member.firstName} {member.lastName}
        </Box>
        <Box sx={{ color: 'gray' }}>{member.email}</Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            textTransform: 'capitalize',
            backgroundColor: 'darkGray',
            color: 'white',
            borderRadius: '20px',
          }}
        >
          {/* Temporaly fix, this type should be added in the request response */}
          {MEMBER_ROLES[member.role as keyof typeof MEMBER_ROLES]}
        </Box>
        {/* <Box>
          <MoreVertIcon
            sx={{
              height: 30,
              width: 30,
              ml: 2,
              ':hover': { cursor: 'pointer' },
            }}
            onClick={handleMenuClick}
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography sx={{ p: 2 }}>Update popover coming soon...</Typography>
          </Popover>
        </Box> */}
      </Box>
    </Box>
  );
};

export default MemberRow;
