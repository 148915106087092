import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { RECIPIENT, RecipientType } from '@/fleet/pages/private/Transactions';

type EmailReceiptModalType = {
  handleClose: () => void;
  receiptNum: string;
  recipient: RecipientType;
  setRecipient: Dispatch<SetStateAction<RecipientType>>;
  selectedDriverEmail: string;
  handleSendEmail: () => void;
  handleCustomEmail: (e: string) => void;
  customEmail: string | null;
};

const boxStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 2,
  m: 1,
};

const closeStyle = {
  height: 40,
  textTransform: 'none',
  color: '#181818',
  padding: '15px',
};

const submitStyle = {
  background: '#181818',
  height: 40,
  textTransform: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#353535',
  },
  padding: '15px',
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const EmailReceiptModal = ({
  handleClose,
  receiptNum,
  recipient,
  setRecipient,
  selectedDriverEmail,
  handleSendEmail,
  handleCustomEmail,
  customEmail,
}: EmailReceiptModalType) => {
  const [inputValue, setInputValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const recipientRef = useRef(recipient);

  const handleRadioChange = (recipient: RECIPIENT | null) => {
    recipientRef.current = recipient;
  };

  const handleInputChange = (email: string) => {
    setInputValue(email);
  };

  const resetEmailFields = () => {
    setInputValue('');
    setErrorText('');
  };

  const handleSuccessfulSubmit = () => {
    handleSendEmail();
    resetEmailFields();
  };

  const handleSubmit = () => {
    if (recipient !== RECIPIENT.OTHER) {
      handleSuccessfulSubmit();
      return;
    }

    if (customEmail && emailRegex.test(customEmail)) {
      handleSuccessfulSubmit();
      return;
    }

    setErrorText('Valid email required');
  };

  return (
    <Modal open onClose={handleClose}>
      <Box sx={boxStyle}>
        <FormControl>
          <Typography variant="h6">
            Select email recipient for receipt #{receiptNum}:
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            style={{ marginTop: 2 }}
            onChange={(e) => {
              handleRadioChange(e.target.value as RecipientType);
              setRecipient(e.target.value as RecipientType);
            }}
          >
            <FormControlLabel value="user" control={<Radio />} label="Myself" />
            <FormControlLabel
              value="driver"
              control={<Radio />}
              label={
                selectedDriverEmail
                  ? `Driver: ${selectedDriverEmail}`
                  : 'Driver'
              }
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label={
                <TextField
                  type="email"
                  placeholder="Email address"
                  value={inputValue}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                    handleCustomEmail(e.target.value);
                  }}
                  disabled={recipient !== RECIPIENT.OTHER}
                  helperText={errorText}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 4,
                      color: 'red',
                    },
                  }}
                />
              }
            />
          </RadioGroup>
        </FormControl>
        <Box display="flex" mt={3} justifyContent="flex-end">
          <Button
            sx={closeStyle}
            onClick={() => {
              handleClose();
              resetEmailFields();
            }}
            style={{ marginRight: 5 }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="secondary"
            sx={submitStyle}
            variant="contained"
            onClick={handleSubmit}
            disabled={!recipient}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
