import * as React from 'react';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Toolbar from '@mui/material/Toolbar';
import { AppBar as MUIAppBar, Box, Button, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  toolBar: {
    margin: 'auto',
    maxWidth: 1160,
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}));

type NavItemsProps = {
  label: string;
  link: string;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  sx?: SxProps;
  endIcon?: React.ReactNode;
};

const commonButtonSx = {
  fontFamily: 'Mulish, Roboto',
  fontSize: '15px',
  fontWeight: '700',
  textTransform: 'none',
};

const linkButtonSx: SxProps = {
  mr: 3.4,
  background: '#fff',
  color: '#000',
  padding: '6px 20px 6px 20px',
  '&:hover': {
    background: '#fff',
    color: '#181818',
  },
  ...commonButtonSx,
};

const navItems: NavItemsProps[] = [
  {
    label: 'Home',
    link: 'https://onrampcard.com',
    sx: linkButtonSx,
  },
  {
    label: 'Company',
    link: 'https://onrampcard.com/about/',
    sx: linkButtonSx,
  },
  {
    label: 'Careers',
    link: 'https://onrampcard.com/careers/',
    sx: linkButtonSx,
  },
  {
    label: 'Contact',
    link: 'https://onrampcard.com/contact-us/',
    variant: 'contained',
    sx: {
      padding: '10px 30px 10px 30px',
      background: '#181818',
      borderRadius: '9px',
      width: '152px',
      '&:hover': {
        background: '#f6f04f',
        color: '#181818',
      },
      ...commonButtonSx,
    },
    endIcon: <NorthEastIcon />,
  },
];

export function PublicAppBar() {
  const classes = useStyles();

  return (
    <MUIAppBar elevation={0} position="fixed" sx={{ background: '#fff' }}>
      <Toolbar className={classes.toolBar}>
        <Box component="a" href="https://onrampcard.com/">
          <Box
            component="img"
            sx={{
              height: 25,
            }}
            alt="Onramp logo"
            src="/h_logo.svg"
          />
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }} ml={55}>
          {navItems.map((item: NavItemsProps) => {
            return (
              <Button
                disableElevation
                key={item.label}
                href={item.link}
                variant={item.variant}
                sx={item.sx}
                endIcon={item.endIcon}
              >
                {item.label}
              </Button>
            );
          })}
        </Box>
      </Toolbar>
    </MUIAppBar>
  );
}
