import * as React from 'react';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Alert,
} from '@mui/material';
import { FormikProps } from 'formik';
import { PatternFormat } from 'react-number-format';
import { useFormStyles } from '../styles';

export { validationSchema } from './validation';

export type FormValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  authorizedOfficer: boolean;
};

type FormPropsType = {
  formik: FormikProps<FormValuesType>;
};

function getInitialValuesFromURL(): Partial<FormValuesType> {
  const urlParams = new URLSearchParams(window.location.search);

  const values = {
    firstName: urlParams.get('contactName[first]') || '',
    lastName: urlParams.get('contactName[last]') || '',
    email: urlParams.get('workEmail') || '',
  };

  // Clear the query strings
  window.history.replaceState({}, document.title, window.location.pathname);

  return values;
}

export const initialValues: FormValuesType = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  authorizedOfficer: false,
  ...getInitialValuesFromURL(),
};

export function Form(props: FormPropsType) {
  const { formik } = props;
  const classes = useFormStyles();

  const handleBlur = async (
    e: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name } = e.target;
    formik.handleBlur(e);

    // Validate the specific field and update the touched state
    await formik.validateField(name);
    formik.setFieldTouched(name, true, false); // false to prevent setting field as touched if no error
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked);
  };

  return (
    <>
      <Alert severity="info" sx={{ my: 3 }}>
        {`Basic business information collected here will be used by ONRAMP Payments, Inc to determine your company's credit limit.`}
      </Alert>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="firstName"
            label="First Name *"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName ? formik.errors.firstName : ''}
            fullWidth
            margin="normal"
            className={classes.textField}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="lastName"
            label="Last Name *"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName ? formik.errors.lastName : ''}
            fullWidth
            margin="normal"
            className={classes.textField}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="email"
            label="Work Email *"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ''}
            fullWidth
            margin="normal"
            className={classes.textField}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PatternFormat
            customInput={TextField}
            format="###-###-####"
            mask="_"
            name="phone"
            label="Work Phone *"
            value={formik.values.phone}
            onBlur={handleBlur}
            onValueChange={(values) => {
              formik.setFieldValue('phone', values.formattedValue);
            }}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone ? formik.errors.phone : ''}
            fullWidth
            margin="normal"
            className={classes.textField}
          />
        </Grid>
      </Grid>
      <FormControl
        error={
          formik.touched.authorizedOfficer &&
          Boolean(formik.errors.authorizedOfficer)
        }
        component="fieldset"
      >
        <FormControlLabel
          control={
            <Checkbox
              name="authorizedOfficer"
              checked={formik.values.authorizedOfficer}
              onChange={handleCheckboxChange}
            />
          }
          label="I am an authorized officer or beneficial owner of the business *"
        />
        {formik.touched.authorizedOfficer &&
          formik.errors.authorizedOfficer && (
            <FormHelperText>{formik.errors.authorizedOfficer}</FormHelperText>
          )}
      </FormControl>
    </>
  );
}
