import cardApplicationModel from './creditApplicationModel';

const {
  formField: {
    carrierType,
    businessName,
    businessEmail,
    businessAddress1,
    businessAddress2,
    businessRegion,
    businessCity,
    businessZipCode,
    businessCountry,
    businessPhoneNumber,
    businessEin,
    primaryContactEmail,
    primaryContactName,
    primaryContactPhoneNumber,

    driverId,
    numberOfDrivers,
    departmentOfTransportationNumber,
    motorCarrierNumber,
    estimatedWeeklySpent,

    ownerlegalName,
    ownerSsn,
    ownerDateOfBirth,
    ownerDriverLicense,
    ownerAddress1,
    ownerAddress2,
    ownerRegion,
    ownerCity,
    ownerZipCode,
    ownerCountry,
  },
} = cardApplicationModel;

export default {
  [carrierType.name]: '',
  [businessName.name]: '',
  [businessEmail.name]: '',
  [businessAddress1.name]: '',
  [businessAddress2.name]: '',
  [businessRegion.name]: '',
  [businessCity.name]: '',
  [businessZipCode.name]: '',
  [businessCountry.name]: 'USA',
  [businessPhoneNumber.name]: '',
  [businessEin.name]: '',

  [primaryContactEmail.name]: '',
  [primaryContactName.name]: '',
  [primaryContactPhoneNumber.name]: '',

  [driverId.name]: '',
  [numberOfDrivers.name]: '',
  [departmentOfTransportationNumber.name]: '',
  [motorCarrierNumber.name]: '',
  [estimatedWeeklySpent.name]: null,

  [ownerlegalName.name]: '',
  [ownerSsn.name]: '',
  [ownerDateOfBirth.name]: '',
  [ownerDriverLicense.name]: '',
  [ownerAddress1.name]: '',
  [ownerAddress2.name]: '',
  [ownerRegion.name]: '',
  [ownerCity.name]: '',
  [ownerZipCode.name]: '',
  [ownerCountry.name]: 'USA',
};
