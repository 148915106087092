import {
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { SmallSkeleton } from './SmallSkeleton';
import { commonStyles } from './shared/commonStyles';
import { getDuration } from './shared/getDuration';

const RetailSpend = ({ dashboardData, selectedIndex }: any) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const totalDollars = useMemo(() => {
    if (dashboardData?.totalDollars) {
      return `$${parseFloat(dashboardData?.totalDollars).toFixed(2)}`;
    }
  }, [dashboardData?.totalDollars]);

  const totalDiscounted = useMemo(() => {
    if (dashboardData?.totalDiscounted) {
      return `$${parseFloat(dashboardData?.totalDiscounted).toFixed(2)}`;
    }
  }, [dashboardData?.totalDiscounted]);

  return (
    <Box sx={commonStyles.container}>
      <Typography sx={commonStyles.title}>Retail Spend</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography sx={commonStyles.textCenter}>
            LAST {getDuration(selectedIndex)}
          </Typography>
          {totalDollars ? (
            <Typography sx={commonStyles.value}>{totalDollars}</Typography>
          ) : (
            <SmallSkeleton />
          )}
        </Box>
        {isSmallScreen ? (
          <Divider orientation="horizontal" flexItem sx={{ my: 2 }} />
        ) : (
          <Divider orientation="vertical" flexItem sx={{ height: '100%' }} />
        )}
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography sx={commonStyles.textCenter}>DISCOUNTED</Typography>
          {totalDiscounted ? (
            <Typography sx={commonStyles.value}>{totalDiscounted}</Typography>
          ) : (
            <SmallSkeleton />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RetailSpend;
