import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import SuccessAlert from '@/fleet/components/common/alerts/success';
import ErrorAlert from '@/fleet/components/common/alerts/error';
import { getAllUsersService } from '@/fleet/services/carriers';

import MemberRow from '@/fleet/components/views/members/MemberRow';
import AddMemberModal from '@/fleet/components/views/members/AddMemberModal';

interface MemberProps {
  email: string;
  enabled: boolean;
  entityUuid: string;
  firstName: string;
  identityUuid: string;
  lastName: string;
  parentUuid: string;
  phoneNumber: string;
  role: string;
}

export function Members() {
  const [containerFlexDirection, setContainerFlexDirection] = useState({
    flexDirection: 'row',
  });
  const [showAddMember, setShowAddMember] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [members, setMembers] = useState<MemberProps[]>([]);
  const [loading, setLoading] = useState(false);
  const { carrierUuid } = useParams();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1300) {
        setContainerFlexDirection({ flexDirection: 'column' });
      } else {
        setContainerFlexDirection({ flexDirection: 'row' });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  useEffect(() => {
    setLoading(true);

    if (!carrierUuid) {
      return;
    }

    getAllUsersService(carrierUuid, 'FLEET_MANAGER')
      .then((response: any) => {
        setMembers(response.data.users);
      })
      .catch(() => {});

    setLoading(false);
  }, [carrierUuid, loading, showSuccessAlert]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          mb: 2,
        }}
      >
        <Typography fontSize={20} fontWeight={600}>
          Members
        </Typography>
        <Button
          sx={{
            backgroundColor: 'black',
            color: 'white',
            mr: 2,
            py: 1,
            px: 2,
            ':hover': {
              color: 'black',
            },
            textTransform: 'capitalize',
          }}
          onClick={() => setShowAddMember(true)}
        >
          + Add member
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={containerFlexDirection}
      >
        <Box
          bgcolor="white"
          borderRadius="10px"
          border={1}
          borderColor="#D4D4D4"
          mb={2}
          mr={2}
          overflow="hidden"
          flex={1}
        >
          {members?.map((member, index) => {
            const isLastRow = members.length - 1 === index;
            return (
              <MemberRow
                key={member?.entityUuid}
                member={member}
                isLastRow={isLastRow}
              />
            );
          })}
        </Box>
      </Box>
      {showAddMember ? (
        <AddMemberModal
          handleClose={() => setShowAddMember(false)}
          setShowSuccessAlert={setShowSuccessAlert}
          setShowErrorAlert={setShowErrorAlert}
          setAlertMessage={setAlertMessage}
          setLoading={setLoading}
        />
      ) : null}
      <SuccessAlert
        message="Added user successfully!"
        open={showSuccessAlert}
        handleClose={() => setShowSuccessAlert(false)}
      />
      <ErrorAlert
        message={`An error occurred when trying to add member. Please try again later. ${alertMessage}`}
        open={showErrorAlert}
        handleClose={() => setShowErrorAlert(false)}
      />
    </>
  );
}
