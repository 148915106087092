import axios, { AxiosResponse, AxiosError } from 'axios';
import { Config } from '../config/env';

const apiUrl = Config.METRICS_API_URL;

export const getVehicleEligibility = async (
  vins: string[],
): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .post(`${apiUrl}/eligibility`, { vins })
    .then((response) => response)
    .catch((error) => error);
};

export const createSubscriptions = async (
  vins: string[],
): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .post(`${apiUrl}/subscriptions`, { vins })
    .then((response) => response)
    .catch((error) => error);
};

export const cancelSubscriptions = async (
  vins: string[],
): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .delete(`${apiUrl}/subscriptions`, { data: { vins } })
    .then((response) => response)
    .catch((error) => error);
};
