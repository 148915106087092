const envSettings = window as any;

function getEnvValue(name: string) {
  const envName = process.env[`REACT_APP_${name}`];
  return envName !== undefined ? envName : envSettings[name];
}

export class Config {
  static REPORT_API_URL = getEnvValue('REPORT_API_URL');

  static CARRIER_API_URL = getEnvValue('CARRIER_API_URL');

  static MERCHANT_API_URL = getEnvValue('MERCHANT_API_URL');

  static METRICS_API_URL = getEnvValue('METRICS_API_URL');

  static AUTH_URL = getEnvValue('AUTH_URL');

  static MIXPANEL_KEY = getEnvValue('MIXPANEL_KEY');

  static GQL_URL = getEnvValue('GQL_URL');

  static DEBUG = getEnvValue('DEBUG');

  static GOOGLE_MAPS_API_KEY = getEnvValue('GOOGLE_MAPS_API_KEY');

  static FMCSA_WEBKEY = getEnvValue('FMCSA_WEBKEY');
}
