import React, { useMemo } from 'react';
import { styled, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';
import Drawer, { DrawerHeader } from './navigation/Drawer';

import AppBar from './navigation/AppBar';
import AuthService from '@/fleet/services/auth';
import { Config } from '@/fleet/config/env';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

interface Props {
  children?: React.ReactNode;
  carrierUuid: string;
}

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  background: '#f8f8fa',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  '& .MuiTableCell-head': {
    paddingLeft: 0,
    marginLeft: 10,
  },
}));

const thm = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    background: {
      default: '#f8f8fa',
    },
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        toolButton: {
          marginLeft: '15px',
          paddingLeft: 0,
        },
      },
    },
    MUIDataTableViewCol: {
      styleOverrides: {
        title: {
          padding: '14px 10px 0px 10px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        // @ts-expect-error: TS2322
        labelPlacementEnd: {
          paddingLeft: '15px',
        },
      },
    },
  },
});

const PageLayout = ({ children, carrierUuid }: Props) => {
  const token = AuthService.getToken();

  const client = useMemo(() => {
    return new ApolloClient({
      uri: Config.GQL_URL,
      cache: new InMemoryCache(),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  }, [token]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={thm}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <AppBar />
          <Drawer carrierUuid={carrierUuid} />
          <Main>
            <DrawerHeader />
            {children}
          </Main>
        </Box>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default PageLayout;
