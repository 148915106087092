import {
  AppBar as MUIAppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

type NavItemType = {
  label: string;
  link: string;
};

const navItems: NavItemType[] = [
  {
    label: 'Home',
    link: 'https://onrampfleet.com/',
  },
  {
    label: 'Company',
    link: 'https://onrampfleet.com/about',
  },
];

export function PublicAppBarV2() {
  return (
    <MUIAppBar
      elevation={0}
      position="fixed"
      sx={{ background: '#fff', height: '86px' }}
    >
      <Toolbar
        sx={{
          margin: 'auto',
          maxWidth: 1160,
          width: '100%',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Box component="a" href="https://onrampfleet.com/">
          <Box
            component="img"
            sx={{ height: 25 }}
            alt="Onramp logo"
            src="/h_logo.svg"
          />
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'block' }, ml: 5 }}>
          {navItems.map((item) => (
            <Button
              disableElevation
              key={item.label}
              href={item.link}
              sx={{
                fontFamily: 'Mulish, Roboto',
                fontSize: '15px',
                fontWeight: '700',
                textTransform: 'none',
                mr: 2,
                background: '#fff',
                color: '#000',
                padding: '6px 20px',
                '&:hover': {
                  background: '#fff',
                  color: '#181818',
                },
              }}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        <Box sx={{ display: { xs: 'block', sm: 'none' }, ml: 'auto' }}>
          <IconButton color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </MUIAppBar>
  );
}
