import React from 'react';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import InputField from '@/fleet/components/common/fields/InputField';
import SelectField from '@/fleet/components/common/fields/SelectField';
import { States } from '@/fleet/components/helpers/States';

export default function BusinessInformation(props: any) {
  const {
    formField: {
      ownerlegalName,
      ownerSsn,
      ownerDateOfBirth,
      ownerDriverLicense,
      ownerAddress1,
      ownerAddress2,
      ownerRegion,
      ownerCity,
      ownerZipCode,
      ownerCountry,
    },
  } = props;

  return (
    <>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            name={ownerlegalName.name}
            label={ownerlegalName.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField name={ownerSsn.name} label={ownerSsn.label} fullWidth />
          <FormHelperText>
            Used to confirm identity, will not affect credit
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={ownerDriverLicense.name}
            label={ownerDriverLicense.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={ownerDateOfBirth.name}
            label={ownerDateOfBirth.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={ownerAddress1.name}
            label={ownerAddress1.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={ownerAddress2.name}
            label={ownerAddress2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            name={ownerRegion.name}
            label={ownerRegion.label}
            data={States.toOptions()}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField name={ownerCity.name} label={ownerCity.label} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={ownerZipCode.name}
            label={ownerZipCode.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={ownerCountry.name}
            label={ownerCountry.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
