import * as React from 'react';
import { Grid, TextField, Autocomplete, Box, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { PatternFormat, NumericFormat } from 'react-number-format';
import { FormikProps } from 'formik';
import { useFormStyles } from '../styles';
import * as constants from '../constants';
import { InputZipCode } from '../components/InputZipCode';
import { InputUSDOT } from '../components/InputUSDOT';
import * as serviceAddress from '../components/InputZipCode/service';

export { validationSchema } from './validation';

export type FormValuesType = {
  businessName: string;
  subsidiary: string;
  numberOfTrucks: string;
  stateOfIncorporation: string;
  federalIdNumber: string;
  industryType: string;
  typeOfBusiness: string;
  yearsInBusiness: string;
  annualRevenue: string;
  weeklyFuelSpend: string;
  businessAddress1: string;
  businessAddress2: string;
  businessCity: string;
  businessState: string;
  businessZip: string;
  businessCountry: string;
  businessPhoneNumber: string;
  usDotNumber: string;
  mcNumber: string;
};

type FormPropsType = {
  formik: FormikProps<FormValuesType>;
};

export const initialValues: FormValuesType = {
  businessName: '',
  subsidiary: '',
  numberOfTrucks: '',
  stateOfIncorporation: '',
  federalIdNumber: '',
  industryType: '',
  typeOfBusiness: '',
  yearsInBusiness: '',
  annualRevenue: '',
  weeklyFuelSpend: '',
  businessAddress1: '',
  businessAddress2: '',
  businessCity: '',
  businessState: '',
  businessZip: '',
  businessCountry: '',
  businessPhoneNumber: '',
  usDotNumber: '',
  mcNumber: '',
};

export function Form(props: FormPropsType) {
  const { formik } = props;
  const classes = useFormStyles();
  const [isZipLoading, setIsZipLoading] = React.useState(false);
  const [isUSDOTLoading, setIsUSDOTLoading] = React.useState(false);

  const [addressSuggestions, setAddressSuggestions] = React.useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const handleBusinessAddress1Change = async (value: string) => {
    formik.setFieldValue('businessAddress1', value);
    if (value.length > 2) {
      const suggestions = await serviceAddress.getAddressSuggestions(value);
      setAddressSuggestions(suggestions);
    }
  };

  const debouncedHandleBusinessAddress1Change = React.useMemo(
    () =>
      debounce(async (value: string) => {
        if (value.length > 2) {
          const suggestions = await serviceAddress.getAddressSuggestions(value);
          setAddressSuggestions(suggestions);
        }
      }, 300),
    [],
  );

  React.useEffect(() => {
    if (formik.values.businessAddress1) {
      debouncedHandleBusinessAddress1Change(formik.values.businessAddress1);
    }
  }, [formik.values.businessAddress1]);

  const handleBlur = async (
    e: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name } = e.target;
    formik.handleBlur(e);
    await formik.validateField(name);
    formik.setFieldTouched(name, true, false); // false to prevent setting field as touched if no error
  };

  const handleAutocompleteBlur = async (
    e: React.FocusEvent<unknown>,
    fieldName: keyof FormValuesType,
  ) => {
    formik.setFieldTouched(fieldName, true, false);
    await formik.validateField(fieldName);
  };

  const handleZipCodeChange = async (zipCode: string) => {
    formik.setFieldValue('businessZip', zipCode);
    if (zipCode.length === 5) {
      try {
        const info = await serviceAddress.getZipCodeInfo(zipCode);
        formik.setFieldValue('businessCity', info.city);
        formik.setFieldValue('businessState', info.state);
        formik.setFieldValue('businessCountry', info.country);

        // Get address suggestions based on the ZIP code
        const suggestions = await serviceAddress.getAddressSuggestions(
          `${info.city}, ${info.state} ${zipCode}`,
        );
        setAddressSuggestions(suggestions);
      } catch (error) {
        console.error('Error fetching ZIP code info:', error);
      }
    }
  };

  return (
    <>
      <Box my={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Federal Motor Carrier Safety Administration Information
            </Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <InputUSDOT
                    name="usDotNumber"
                    label="US DOT Number *"
                    value={formik.values.usDotNumber}
                    onChange={(value) =>
                      formik.setFieldValue('usDotNumber', value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.usDotNumber &&
                      Boolean(formik.errors.usDotNumber)
                    }
                    helperText={
                      formik.touched.usDotNumber
                        ? formik.errors.usDotNumber
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    validateField={formik.validateField}
                    onLoadingChange={setIsUSDOTLoading}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    name="mcNumber"
                    label="MC Number"
                    value={formik.values.mcNumber}
                    onChange={formik.handleChange}
                    onBlur={handleBlur}
                    error={
                      formik.touched.mcNumber && Boolean(formik.errors.mcNumber)
                    }
                    helperText={
                      formik.touched.mcNumber ? formik.errors.mcNumber : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    inputProps={{ maxLength: 8 }}
                    disabled={isUSDOTLoading}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Business Information</Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="businessName"
                    label="Business Name *"
                    value={formik.values.businessName}
                    onChange={formik.handleChange}
                    onBlur={handleBlur}
                    error={
                      formik.touched.businessName &&
                      Boolean(formik.errors.businessName)
                    }
                    helperText={
                      formik.touched.businessName
                        ? formik.errors.businessName
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="subsidiary"
                    label="Subsidiary or Doing Business As *"
                    value={formik.values.subsidiary}
                    onChange={formik.handleChange}
                    onBlur={handleBlur}
                    error={
                      formik.touched.subsidiary &&
                      Boolean(formik.errors.subsidiary)
                    }
                    helperText={
                      formik.touched.subsidiary ? formik.errors.subsidiary : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    inputProps={{ maxLength: 60 }}
                    disabled={isUSDOTLoading}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <PatternFormat
                    customInput={TextField}
                    format="##-#######"
                    mask="_"
                    name="federalIdNumber"
                    label="Federal ID Number *"
                    value={formik.values.federalIdNumber}
                    onBlur={handleBlur}
                    onValueChange={(values) => {
                      formik.setFieldValue(
                        'federalIdNumber',
                        values.formattedValue,
                      );
                    }}
                    error={
                      formik.touched.federalIdNumber &&
                      Boolean(formik.errors.federalIdNumber)
                    }
                    helperText={
                      formik.touched.federalIdNumber
                        ? formik.errors.federalIdNumber
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    disabled={isUSDOTLoading}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Autocomplete
                    options={constants.STATES}
                    getOptionLabel={(option) => option || ''}
                    value={formik.values.stateOfIncorporation || null}
                    onChange={(event, value) => {
                      formik.setFieldValue('stateOfIncorporation', value || '');
                    }}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()) ||
                          constants.STATE_NAMES[option]
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    onBlur={(e) =>
                      handleAutocompleteBlur(e, 'stateOfIncorporation')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="stateOfIncorporation"
                        label="State of Incorporation *"
                        error={
                          formik.touched.stateOfIncorporation &&
                          Boolean(formik.errors.stateOfIncorporation)
                        }
                        helperText={
                          formik.touched.stateOfIncorporation
                            ? formik.errors.stateOfIncorporation
                            : ''
                        }
                        margin="normal"
                        className={classes.textField}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <NumericFormat
                    customInput={TextField}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return (
                        floatValue === undefined ||
                        (floatValue >= 0 && floatValue <= 99999)
                      );
                    }}
                    decimalScale={0}
                    name="numberOfTrucks"
                    label="Number of Trucks *"
                    value={formik.values.numberOfTrucks}
                    onBlur={handleBlur}
                    onValueChange={(values) => {
                      formik.setFieldValue('numberOfTrucks', values.value);
                    }}
                    error={
                      formik.touched.numberOfTrucks &&
                      Boolean(formik.errors.numberOfTrucks)
                    }
                    helperText={
                      formik.touched.numberOfTrucks
                        ? formik.errors.numberOfTrucks
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <NumericFormat
                    customInput={TextField}
                    allowNegative={false}
                    valueIsNumericString
                    name="yearsInBusiness"
                    label="Years in Business *"
                    value={formik.values.yearsInBusiness}
                    onBlur={handleBlur}
                    onValueChange={(values) => {
                      formik.setFieldValue('yearsInBusiness', values.value);
                    }}
                    error={
                      formik.touched.yearsInBusiness &&
                      Boolean(formik.errors.yearsInBusiness)
                    }
                    helperText={
                      formik.touched.yearsInBusiness
                        ? formik.errors.yearsInBusiness
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    inputProps={{ maxLength: 2 }}
                    disabled={isUSDOTLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <PatternFormat
                    customInput={TextField}
                    format="###-###-####"
                    mask="_"
                    name="businessPhoneNumber"
                    label="Business Phone Number *"
                    value={formik.values.businessPhoneNumber}
                    onBlur={handleBlur}
                    onValueChange={(values) => {
                      formik.setFieldValue(
                        'businessPhoneNumber',
                        values.formattedValue,
                      );
                    }}
                    error={
                      formik.touched.businessPhoneNumber &&
                      Boolean(formik.errors.businessPhoneNumber)
                    }
                    helperText={
                      formik.touched.businessPhoneNumber
                        ? formik.errors.businessPhoneNumber
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    options={constants.INDUSTRY_TYPES}
                    getOptionLabel={(option) => option}
                    value={formik.values.industryType}
                    onChange={(event, value) => {
                      formik.setFieldValue('industryType', value);
                    }}
                    onBlur={(e) => handleAutocompleteBlur(e, 'industryType')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="industryType"
                        label="Industry Type *"
                        error={
                          formik.touched.industryType &&
                          Boolean(formik.errors.industryType)
                        }
                        helperText={
                          formik.touched.industryType
                            ? formik.errors.industryType
                            : ''
                        }
                        margin="normal"
                        className={classes.textField}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    options={constants.BUSINESS_TYPES}
                    getOptionLabel={(option) => option}
                    value={formik.values.typeOfBusiness}
                    onChange={(event, value) => {
                      formik.setFieldValue('typeOfBusiness', value);
                    }}
                    onBlur={(e) => handleAutocompleteBlur(e, 'typeOfBusiness')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="typeOfBusiness"
                        label="Type of Business *"
                        error={
                          formik.touched.typeOfBusiness &&
                          Boolean(formik.errors.typeOfBusiness)
                        }
                        helperText={
                          formik.touched.typeOfBusiness
                            ? formik.errors.typeOfBusiness
                            : ''
                        }
                        margin="normal"
                        className={classes.textField}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return (
                        floatValue === undefined || floatValue <= 1000000000
                      );
                    }}
                    name="annualRevenue"
                    label="Annual Revenue *"
                    value={formik.values.annualRevenue}
                    onValueChange={(values) => {
                      formik.setFieldValue('annualRevenue', values.value);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('annualRevenue', true);
                      formik.validateField('annualRevenue');
                    }}
                    error={
                      formik.touched.annualRevenue &&
                      Boolean(formik.errors.annualRevenue)
                    }
                    helperText={
                      formik.touched.annualRevenue
                        ? formik.errors.annualRevenue
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue === undefined || floatValue <= 1000000;
                    }}
                    name="weeklyFuelSpend"
                    label="Weekly Fuel Spend *"
                    value={formik.values.weeklyFuelSpend}
                    onValueChange={(values) => {
                      formik.setFieldValue('weeklyFuelSpend', values.value);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('weeklyFuelSpend', true);
                      formik.validateField('weeklyFuelSpend');
                    }}
                    error={
                      formik.touched.weeklyFuelSpend &&
                      Boolean(formik.errors.weeklyFuelSpend)
                    }
                    helperText={
                      formik.touched.weeklyFuelSpend
                        ? formik.errors.weeklyFuelSpend
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Business Address</Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputZipCode
                    name="businessZip"
                    label="ZIP Code *"
                    value={formik.values.businessZip}
                    onChange={handleZipCodeChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.businessZip &&
                      Boolean(formik.errors.businessZip)
                    }
                    helperText={
                      formik.touched.businessZip
                        ? formik.errors.businessZip
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    onZipCodeInfo={(info) => {
                      if (info) {
                        const { city, state, country } = info;
                        formik.setFieldValue('businessCity', city);
                        formik.setFieldValue('businessState', state);
                        formik.setFieldValue('businessCountry', country);

                        formik.setFieldTouched('businessCity', false);
                        formik.setFieldTouched('businessState', false);
                        formik.setFieldTouched('businessCountry', false);

                        formik.validateField('businessCity');
                        formik.validateField('businessState');
                        formik.validateField('businessCountry');
                      } else {
                        // Handle error case, maybe clear or reset fields
                        formik.setFieldValue('businessCity', '');
                        formik.setFieldValue('businessState', '');
                        formik.setFieldValue('businessCountry', '');
                      }
                    }}
                    setFieldTouched={formik.setFieldTouched}
                    validateField={formik.validateField}
                    onLoadingChange={setIsZipLoading}
                    disabled={isUSDOTLoading}
                    useGoogleMaps
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="businessCity"
                    label="City *"
                    value={formik.values.businessCity}
                    onChange={formik.handleChange}
                    disabled={isZipLoading || isUSDOTLoading}
                    onBlur={handleBlur}
                    error={
                      formik.touched.businessCity &&
                      Boolean(formik.errors.businessCity)
                    }
                    helperText={
                      formik.touched.businessCity
                        ? formik.errors.businessCity
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    options={constants.STATES}
                    getOptionLabel={(option) => option}
                    value={formik.values.businessState}
                    disabled={isZipLoading || isUSDOTLoading}
                    onChange={(event, value) => {
                      formik.setFieldValue('businessState', value);
                    }}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()) ||
                          constants.STATE_NAMES[option]
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    onBlur={(e) => handleAutocompleteBlur(e, 'businessState')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="businessState"
                        label="State *"
                        disabled={isZipLoading || isUSDOTLoading}
                        error={
                          formik.touched.businessState &&
                          Boolean(formik.errors.businessState)
                        }
                        helperText={
                          formik.touched.businessState
                            ? formik.errors.businessState
                            : ''
                        }
                        margin="normal"
                        className={classes.textField}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    options={constants.COUNTRIES}
                    getOptionLabel={(option) => option}
                    value={formik.values.businessCountry}
                    disabled={isZipLoading || isUSDOTLoading}
                    onChange={(event, value) => {
                      formik.setFieldValue('businessCountry', value);
                    }}
                    onBlur={(e) => handleAutocompleteBlur(e, 'businessCountry')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="businessCountry"
                        label="Country *"
                        disabled={isZipLoading || isUSDOTLoading}
                        error={
                          formik.touched.businessCountry &&
                          Boolean(formik.errors.businessCountry)
                        }
                        helperText={
                          formik.touched.businessCountry
                            ? formik.errors.businessCountry
                            : ''
                        }
                        margin="normal"
                        className={classes.textField}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={addressSuggestions}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.description
                    }
                    freeSolo
                    value={formik.values.businessAddress1}
                    onInputChange={(event, newInputValue) => {
                      handleBusinessAddress1Change(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="businessAddress1"
                        label="Address Line 1 *"
                        onBlur={handleBlur}
                        disabled={isUSDOTLoading}
                        error={
                          formik.touched.businessAddress1 &&
                          Boolean(formik.errors.businessAddress1)
                        }
                        helperText={
                          formik.touched.businessAddress1
                            ? formik.errors.businessAddress1
                            : ''
                        }
                        fullWidth
                        margin="normal"
                        className={classes.textField}
                      />
                    )}
                    onChange={(event, value) => {
                      formik.setFieldValue(
                        'businessAddress1',
                        typeof value === 'string'
                          ? value
                          : value?.description || '',
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="businessAddress2"
                    label="Address Line 2"
                    value={formik.values.businessAddress2}
                    onChange={formik.handleChange}
                    onBlur={handleBlur}
                    error={
                      formik.touched.businessAddress2 &&
                      Boolean(formik.errors.businessAddress2)
                    }
                    helperText={
                      formik.touched.businessAddress2
                        ? formik.errors.businessAddress2
                        : ''
                    }
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
