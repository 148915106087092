import React, { Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import toast from 'react-hot-toast';

import { setCarrierPromptsService } from '@/fleet/services/carriers';
import { PromptValuesType, TODAY, promptInitialValues } from './constants';

interface PromptSettingsType {
  promptFormValues: PromptValuesType;
  carrierUuid: string | undefined;
  editingPrompts: boolean;
  setEditingPrompts: Dispatch<SetStateAction<boolean>>;
  setPromptFormValues: React.Dispatch<
    React.SetStateAction<{
      hasTripNumber: boolean;
      hasTruckNumber: boolean;
      hasTrailerNumber: boolean;
      hasOdometer: boolean;
    }>
  >;
}

const PromptSettings = ({
  promptFormValues,
  carrierUuid,
  editingPrompts,
  setEditingPrompts,
  setPromptFormValues,
}: PromptSettingsType) => {
  return (
    <Formik
      initialValues={promptFormValues}
      enableReinitialize
      onSubmit={(values) => {
        if (!carrierUuid) {
          return;
        }
        const submitValues = {
          ...values,
          asOfDate: TODAY,
          carrierUuid,
        };
        toast
          .promise(setCarrierPromptsService(submitValues, carrierUuid), {
            loading: 'Saving...',
            success: 'Prompt settings saved!',
            error: 'Unable to save prompt settings. Please try again later.',
          })
          .then(() => {
            setPromptFormValues(values);
            setEditingPrompts(false);
          });
      }}
    >
      {({ values, handleChange, handleSubmit, resetForm, dirty }) => (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            border={1}
            borderColor="#D4D4D4"
            bgcolor="#F8F8FA"
            fontWeight={600}
            fontSize={14}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Box>PROMPTS</Box>
            <Box>
              {editingPrompts && (
                <Button
                  sx={{
                    width: 100,
                    paddingX: 3,
                    paddingY: 1,
                    mr: 1,
                    bgcolor: 'black',
                    color: 'white',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                  }}
                  onClick={() => {
                    dirty ? handleSubmit() : toast('No changes made');
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                sx={{
                  width: 100,
                  paddingX: 3,
                  paddingY: 1,
                  border: 1,
                  borderColor: editingPrompts ? 'gray' : null,
                  bgcolor: editingPrompts ? 'white' : 'black',
                  color: editingPrompts ? 'black' : 'white',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'gray',
                  },
                }}
                onClick={() => {
                  if (editingPrompts) {
                    setEditingPrompts(false);
                    resetForm({ values: promptInitialValues });
                  } else {
                    setEditingPrompts(true);
                  }
                }}
              >
                {editingPrompts ? 'Cancel' : 'Edit'}
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            p={2}
            border={1}
            borderTop={0}
            borderColor="#D4D4D4"
            bgcolor="white"
            sx={{
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <Box>Select all that apply</Box>
              <FormGroup sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingPrompts}
                  control={
                    <Checkbox
                      checked={values.hasTruckNumber}
                      onChange={handleChange}
                      name="hasTruckNumber"
                      value={values.hasTruckNumber}
                    />
                  }
                  label="Truck"
                />
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingPrompts}
                  control={
                    <Checkbox
                      checked={values.hasTripNumber}
                      onChange={handleChange}
                      name="hasTripNumber"
                      value={values.hasTripNumber}
                    />
                  }
                  label="Trip"
                />
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingPrompts}
                  control={
                    <Checkbox
                      checked={values.hasTrailerNumber}
                      onChange={handleChange}
                      name="hasTrailerNumber"
                      value={values.hasTrailerNumber}
                    />
                  }
                  label="Trailer"
                />
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingPrompts}
                  control={
                    <Checkbox
                      checked={values.hasOdometer}
                      onChange={handleChange}
                      name="hasOdometer"
                      value={values.hasOdometer}
                    />
                  }
                  label="Odometer"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default PromptSettings;
