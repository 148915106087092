import * as Yup from 'yup';
import cardApplicationModel from './creditApplicationModel';

const {
  formField: {
    carrierType,
    businessName,
    businessEmail,
    businessPhoneNumber,
    businessAddress1,
    businessAddress2,
    businessRegion,
    businessCity,
    businessZipCode,
    businessCountry,
    businessEin,
    primaryContactName,
    primaryContactEmail,
    primaryContactPhoneNumber,

    driverId,
    numberOfDrivers,
    departmentOfTransportationNumber,
    motorCarrierNumber,
    estimatedWeeklySpent,

    ownerDateOfBirth,
    ownerDriverLicense,
    ownerAddress2,
    ownerRegion,
    ownerCity,
    ownerZipCode,
    ownerCountry,
    ownerSsn,
    ownerlegalName,
  },
} = cardApplicationModel;

export default [
  Yup.object().shape({
    [carrierType.name]: Yup.string().required(
      `${carrierType.requiredErrorMsg}`,
    ),
    [businessName.name]: Yup.string().required(
      `${businessName.requiredErrorMsg}`,
    ),
    [businessEmail.name]: Yup.string().required(
      `${businessEmail.requiredErrorMsg}`,
    ),
    [businessPhoneNumber.name]: Yup.string().required(
      `${businessPhoneNumber.requiredErrorMsg}`,
    ),
    [businessAddress1.name]: Yup.string().required(
      `${businessAddress1.requiredErrorMsg}`,
    ),
    [businessAddress2.name]: Yup.string().notRequired(),
    [businessRegion.name]: Yup.string().required(
      `${businessRegion.requiredErrorMsg}`,
    ),
    [businessCity.name]: Yup.string().required(
      `${businessCity.requiredErrorMsg}`,
    ),
    [businessZipCode.name]: Yup.string().required(
      `${businessZipCode.requiredErrorMsg}`,
    ),
    [businessCountry.name]: Yup.string().required(
      `${businessCountry.requiredErrorMsg}`,
    ),
    [businessEin.name]: Yup.string().required(
      `${businessEin.requiredErrorMsg}`,
    ),
    [primaryContactName.name]: Yup.string().notRequired(),
    [primaryContactEmail.name]: Yup.string().notRequired(),
    [primaryContactPhoneNumber.name]: Yup.string().notRequired(),
  }),
  Yup.object().shape({
    [driverId.name]: Yup.string().notRequired(),
    [numberOfDrivers.name]: Yup.string().notRequired(),
    [departmentOfTransportationNumber.name]: Yup.string().required(
      `${departmentOfTransportationNumber.requiredErrorMsg}`,
    ),
    [motorCarrierNumber.name]: Yup.string().required(
      `${motorCarrierNumber.requiredErrorMsg}`,
    ),
    [estimatedWeeklySpent.name]: Yup.number()
      .typeError(`${estimatedWeeklySpent.typeErrorMsg}`)
      .required(`${estimatedWeeklySpent.requiredErrorMsg}`),
  }),

  Yup.object().shape({
    [ownerlegalName.name]: Yup.string().required(
      `${ownerlegalName.requiredErrorMsg}`,
    ),
    [ownerDateOfBirth.name]: Yup.string().required(
      `${ownerDateOfBirth.requiredErrorMsg}`,
    ),
    // [ownerResidentialAddress.name]: Yup.string().required(`${ownerResidentialAddress.requiredErrorMsg}`),
    [ownerSsn.name]: Yup.string().required(`${ownerSsn.requiredErrorMsg}`),
    [ownerDriverLicense.name]: Yup.string().notRequired(),
    [businessAddress1.name]: Yup.string().required(
      `${businessAddress1.requiredErrorMsg}`,
    ),
    [ownerAddress2.name]: Yup.string().notRequired(),
    [ownerRegion.name]: Yup.string().required(
      `${ownerRegion.requiredErrorMsg}`,
    ),
    [ownerCity.name]: Yup.string().required(`${ownerCity.requiredErrorMsg}`),
    [ownerZipCode.name]: Yup.string().required(
      `${ownerZipCode.requiredErrorMsg}`,
    ),
    [ownerCountry.name]: Yup.string().required(
      `${ownerCountry.requiredErrorMsg}`,
    ),
  }),
];
