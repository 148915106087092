import { gql } from '@apollo/client';

export const EMAIL_RECEIPT = gql`
  query EmailReceipt(
    $carrierUuid: String
    $driverUuid: String
    $format: EmailFormats
    $recipients: [String]!
    $transactionUuid: String
  ) {
    sendEmail(
      carrierUuid: $carrierUuid
      driverUuid: $driverUuid
      format: $format
      recipients: $recipients
      transactionUuid: $transactionUuid
    )
  }
`;
