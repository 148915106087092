import { Line } from 'react-chartjs-2';
import { Box, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';
import 'chart.js/auto';

const TransactionChart = ({
  chartData,
  xAxisFormat,
}: {
  chartData: any;
  xAxisFormat: string;
}) => {
  const parsedData = useMemo(() => {
    return chartData?.map(
      (d: { time: string | number | Date; gallon: any }) => {
        return { x: format(new Date(d.time), xAxisFormat), y: d.gallon };
      },
    );
  }, [chartData]);

  const data = {
    type: 'line',
    datasets: [
      {
        data: parsedData,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 8,
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Box minWidth={750}>
      {parsedData ? (
        <Line data={data} options={options} />
      ) : (
        <Skeleton
          variant="rounded"
          animation="wave"
          width={750}
          height={375}
          sx={{ marginBottom: 1.5 }}
        />
      )}
    </Box>
  );
};

export default TransactionChart;
