import React from 'react';
import { InputAdornment, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import InputField from '@/fleet/components/common/fields/InputField';

export default function BusinessInformation(props: any) {
  const {
    formField: {
      driverId,
      numberOfDrivers,
      motorCarrierNumber,
      departmentOfTransportationNumber,
      estimatedWeeklySpent,
    },
  } = props;

  const { values } = useFormikContext<{
    carrierType: string;
  }>();

  return (
    <>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {values.carrierType === 'owner_operator' ? (
            <InputField name={driverId.name} label={driverId.label} fullWidth />
          ) : (
            <InputField
              name={numberOfDrivers.name}
              label={numberOfDrivers.label}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={departmentOfTransportationNumber.name}
            label={departmentOfTransportationNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={motorCarrierNumber.name}
            label={motorCarrierNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={estimatedWeeklySpent.name}
            label={estimatedWeeklySpent.label}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
