import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import toast from 'react-hot-toast';

import { updateCarrierService } from '@/fleet/services/carriers';
import { TODAY } from './constants';
import { useParams } from 'react-router-dom';
import useCarrier from '@/fleet/hooks/useCarrier';

const TankLevelAuthorization = () => {
  const carrier = useCarrier();
  const [tankLevelAuthFormValues, setTankLevelAuthFormValues] = useState({
    fraudPreventionEnabled: carrier.fraudPreventionEnabled || null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const { carrierUuid } = useParams();

  useEffect(() => {
    if (!carrier) return;

    setTankLevelAuthFormValues({
      fraudPreventionEnabled: carrier.fraudPreventionEnabled,
    });
  }, [carrier]);

  return (
    <Formik
      initialValues={tankLevelAuthFormValues}
      enableReinitialize
      onSubmit={(values) => {
        if (!carrierUuid) {
          return;
        }
        const submitValues = {
          carrier: {
            ...carrier,
            fraudPreventionEnabled:
              values.fraudPreventionEnabled === 'true' ? true : false,
            asOfDate: TODAY,
          },
        };

        toast
          .promise(updateCarrierService(submitValues, carrierUuid), {
            loading: 'Saving...',
            success: 'Tank level authorization settings saved!',
            error:
              'Unable to save tank level authorization settings. Please try again later.',
          })
          .then(() => {
            setTankLevelAuthFormValues(values);
            setIsEditing(false);
          });
      }}
    >
      {({ values, handleChange, handleSubmit, resetForm, dirty }) => (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            border={1}
            borderColor="#D4D4D4"
            bgcolor="#F8F8FA"
            fontWeight={600}
            fontSize={14}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Box>TANK LEVEL AUTHORIZATION</Box>
            <Box>
              {isEditing && (
                <Button
                  sx={{
                    width: 100,
                    paddingX: 3,
                    paddingY: 1,
                    mr: 1,
                    bgcolor: 'black',
                    color: 'white',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                  }}
                  onClick={() => {
                    dirty ? handleSubmit() : toast('No changes made');
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                sx={{
                  width: 100,
                  paddingX: 3,
                  paddingY: 1,
                  border: 1,
                  borderColor: isEditing ? 'gray' : null,
                  bgcolor: isEditing ? 'white' : 'black',
                  color: isEditing ? 'black' : 'white',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'gray',
                  },
                }}
                onClick={() => {
                  if (isEditing) {
                    setIsEditing(false);
                    resetForm({
                      values: tankLevelAuthFormValues,
                    });
                  } else {
                    setIsEditing(true);
                  }
                }}
              >
                {isEditing ? 'Cancel' : 'Edit'}
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            p={2}
            border={1}
            borderTop={0}
            borderColor="#D4D4D4"
            bgcolor="white"
            sx={{
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <Typography>Allow tank level authorization</Typography>
              <RadioGroup sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!isEditing}
                  control={
                    <Radio
                      checked={
                        values.fraudPreventionEnabled === 'true' ||
                        values.fraudPreventionEnabled === true
                      }
                      onChange={handleChange}
                      name="fraudPreventionEnabled"
                      value={true}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!isEditing}
                  control={
                    <Radio
                      checked={
                        values.fraudPreventionEnabled === 'false' ||
                        values.fraudPreventionEnabled === false
                      }
                      onChange={handleChange}
                      name="fraudPreventionEnabled"
                      value={false}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default TankLevelAuthorization;
