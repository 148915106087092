import * as React from 'react';
import { Tabs, Tab, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Transactions } from './Transactions';
import { DiscountReports } from './DiscountReports';
import useCarrierProfile from '@/fleet/hooks/useCarrierProfile';

type TypeCustomTabPanel = {
  children?: React.ReactNode;
  showText: boolean;
};

function CustomTabPanel(props: TypeCustomTabPanel) {
  const { children, showText } = props;

  return (
    <Box role="tabpanel" hidden={!showText}>
      {showText && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const Reports = () => {
  const { edParticipation } = useCarrierProfile();
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          textColor="inherit"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              backgroundColor: 'black',
            },
          }}
        >
          <Tab label="Transactions" />
          {edParticipation ? <Tab label="Earned Discounts" /> : null}
        </Tabs>
      </Box>
      <CustomTabPanel showText={value === 0}>
        <Transactions />
      </CustomTabPanel>
      {edParticipation ? (
        <CustomTabPanel showText={value === 1}>
          <DiscountReports />
        </CustomTabPanel>
      ) : null}
    </>
  );
};

export default Reports;
