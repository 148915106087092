import { Box, ImageListItem, LinearProgress, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

const step1 = 'subscription_loading_step_1.png';
const step2 = 'subscription_loading_step_2.png';
const step3 = 'subscription_loading_step_3.png';

const creatingLabels = [
  'Verifying vehicle...',
  'Validating compliance...',
  'Creating subscription',
];

const cancelingLabels = [
  'Retrieving vehicle info...',
  'Retrieving subscription info...',
  'Canceling subscription',
];

export interface Props {
  creating: boolean;
}

const SubscriptionLoadingBar: FC<Props> = ({ creating }) => {
  const [image, setImage] = useState<string>(step1);
  const [labels] = useState<string[]>(
    creating ? creatingLabels : cancelingLabels,
  );
  const [label, setLabel] = useState<string>(labels[0]);

  useEffect(() => {
    const timeout = creating ? 2500 : 1500;
    setTimeout(() => {
      switch (image) {
        case step1:
          setImage(step2);
          setLabel(labels[1]);
          break;
        case step2:
          setImage(step3);
          setLabel(labels[2]);
          break;
        case step3:
      }
    }, timeout);
  }, [image, label]);

  return (
    <>
      <Box sx={{ width: '30%' }}>
        <Typography variant="h5" sx={{ marginBottom: '30px' }}>
          {label}
        </Typography>
        <ImageListItem>
          <img
            srcSet={`/${image}?w=80&h=80&fit=crop&auto=format&dpr=2 2x`}
            src={`/${image}?w=80&h=80&fit=crop&auto=format`}
            alt={image}
            loading="lazy"
          />
        </ImageListItem>
        <LinearProgress />
      </Box>
    </>
  );
};

export default SubscriptionLoadingBar;
