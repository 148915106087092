import React from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import {
  boxStyle,
  cancelStyle,
  submitStyle,
} from '@/fleet/components/common/styles';
import { phoneRegex } from '@/fleet/components/helpers/regex';
import { addUserService } from '@/fleet/services/carriers';
import { Mixpanel } from '@/fleet/config/mixpanel';
import { setPasswordService } from '@/fleet/services/drivers';

interface AddMemberModalProps {
  handleClose: () => void;
  setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FORM_INPUT_FIELDS = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

const validationSchemaObject = Yup.object().shape({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email().required('Required field'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .required('Required field'),
  password: Yup.string().required('Required field'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
};

const AddMemberModal = ({
  handleClose,
  setShowSuccessAlert,
  setShowErrorAlert,
  setAlertMessage,
  setLoading,
}: AddMemberModalProps) => {
  const { carrierUuid } = useParams();

  const addMember = async (values: any) => {
    if (!carrierUuid) {
      return;
    }

    const payloadValues = {
      user: {
        ...values,
        role: 'FLEET_MANAGER',
        parrent_uuid: carrierUuid,
      },
    };

    addUserService(carrierUuid, payloadValues)
      .then((response) => {
        handleClose();
        if (response.status === 200) {
          const passwordValues = {
            password: values.password,
            temporary: true,
            entityUuid: response.data.user.uuid,
            identityUuid: response.data.user.identityUuid,
          };
          setPasswordService(passwordValues)
            .then(() => {
              setShowSuccessAlert(true);
            })
            .catch(() => {
              setShowErrorAlert(true);
            })
            .finally(() => {
              setLoading(false);
            });
          Mixpanel.track('member_added', {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
          });
        } else {
          setAlertMessage(response.statusText);
          setShowErrorAlert(true);
        }
      })
      .catch(() => {
        setShowErrorAlert(true);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={async (values) => {
        await addMember(values);
        setLoading(true);
      }}
    >
      {/* TODO: fix these types */}
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
      }: any) => (
        <Modal open onClose={handleClose} aria-labelledby="modal-title">
          <form onSubmit={handleSubmit}>
            <Box sx={boxStyle}>
              <Typography
                id="modal-title"
                variant="h6"
                component="h1"
                sx={{ mb: 2, mt: 1 }}
              >
                Add Member
              </Typography>
              {FORM_INPUT_FIELDS.map((field) => {
                const { value, label } = field;
                if (value === 'phoneNumber') {
                  return (
                    <InputMask
                      key={value}
                      mask="(999) 999-9999"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {() => (
                        <TextField
                          key={`${value}-${label}`}
                          id={value}
                          label={label}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          sx={{ marginBottom: 2 }}
                          error={touched[value] && !!errors[value]}
                          helperText={touched[value] && errors[value]}
                        />
                      )}
                    </InputMask>
                  );
                }
                return (
                  <TextField
                    key={`${value}-${label}`}
                    id={value}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                    error={touched[value] && !!errors[value]}
                    helperText={touched[value] && errors[value]}
                  />
                );
              })}
              <Box display="flex" mt={3} justifyContent="flex-end">
                <Button sx={cancelStyle} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  sx={submitStyle}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddMemberModal;
