import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { SmallSkeleton } from './SmallSkeleton';
import { commonStyles } from './shared/commonStyles';
import { getDuration } from './shared/getDuration';

const Gallons = ({ dashboardData, selectedIndex }: any) => {
  const totalGallons = useMemo(() => {
    if (dashboardData?.totalGallons) {
      return dashboardData?.totalGallons;
    }
  }, [dashboardData?.totalGallons]);

  return (
    <Box sx={commonStyles.container}>
      <Typography sx={commonStyles.title}>Gallons</Typography>
      <Box sx={commonStyles.content}>
        <Typography sx={commonStyles.textCenter}>
          LAST {getDuration(selectedIndex)}
        </Typography>
        {totalGallons ? (
          <Typography sx={commonStyles.value}>{totalGallons}</Typography>
        ) : (
          <SmallSkeleton />
        )}
      </Box>
    </Box>
  );
};

export default Gallons;
