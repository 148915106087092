import axios from 'axios';
import { Config } from '@/fleet/config/env';

export async function getCarrierCreditLineService(carrierUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/credit-lines`)
    .then((response) => response)
    .catch((error) => error);
}

export async function getCarrierBalance(carrierUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/balance`)
    .then((response) => response)
    .catch((error) => error);
}
