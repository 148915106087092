import * as React from 'react';
import AuthService from '@/fleet/services/auth';

export function Login() {
  React.useEffect(() => {
    AuthService.doLogin();
  }, [AuthService]);

  return <div>Loading...</div>;
}
