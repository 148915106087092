import { gql } from '@apollo/client';

export const GET_DASHBOARD_INFO = gql`
  query GetDashboardInfo($parentUuid: String!, $timeFrame: DashBoardTime!) {
    getDashboardInfo(parentUuid: $parentUuid, timeFrame: $timeFrame) {
      activePump
      totalGallons
      totalDollars
      totalDiscounted
      dataPoints {
        time
        dollar
        gallon
      }
      mostActiveLocations
    }
  }
`;
