import React from 'react';
import { Typography, Grid } from '@mui/material';

function OwnerDetails(props: any) {
  const { formValues } = props;
  const {
    ownerlegalName,
    ownerSsn,
    ownerDateOfBirth,
    ownerDriverLicense,
    ownerAddress1,
    ownerAddress2,
    ownerRegion,
    ownerCity,
    ownerZipCode,
    ownerCountry,
  } = formValues;

  return (
    <Grid item container direction="column" xs={12}>
      <Typography variant="h6" gutterBottom fontWeight={500}>
        Owner Details
      </Typography>
      <Grid container>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Legal Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerlegalName}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>SSN</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerSsn}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>DOB:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerDateOfBirth}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Driver&apos;s License</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerDriverLicense || '-'}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Address1:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerAddress1}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Country:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerCountry}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Address2:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerAddress2 || '-'}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>City:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerCity}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Postal / Zip Code:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerZipCode}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>Region / State:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{ownerRegion}</Typography>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
}

export default OwnerDetails;
