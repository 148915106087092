import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatCamelCase } from '@/fleet/components/helpers/regex';

const hideTheseFields = ['inNetworkIndicator', 'transactionId'];

type TransactionDetailsModalProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  details: any;
};

type NestedObject = {
  [key: string]: NestedObject | string;
};

export const TransactionDetailsModal = ({
  setOpen,
  details,
}: TransactionDetailsModalProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  const mapNestedObject = (
    obj: NestedObject,
    parentKey?: string,
  ): JSX.Element[] => {
    const parsedData: JSX.Element[] = [];

    Object.entries(obj).forEach(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}` : key;

      if (hideTheseFields.includes(currentKey)) {
        return;
      }

      if (typeof value === 'string' && value !== '') {
        let includeDollarSign = false;

        if (
          currentKey.toLowerCase().includes('cost') ||
          currentKey.toLowerCase().includes('total')
        ) {
          includeDollarSign = true;
        }

        parsedData.push(
          <Box
            key={currentKey}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={2}
            bgcolor="#D3D3D340"
            p={2}
            borderRadius={2}
          >
            <Typography mr={5}>{formatCamelCase(currentKey)}:</Typography>
            <Typography>{`${
              includeDollarSign ? '$' : ''
            } ${value}`}</Typography>
          </Box>,
        );
      } else if (typeof value === 'object' && value !== null) {
        parsedData.push(...mapNestedObject(value as NestedObject, currentKey));
      }
    });

    return parsedData;
  };

  return (
    <Dialog open onClose={handleClose} scroll="paper">
      <DialogTitle>Transaction Details</DialogTitle>
      <DialogContent>{mapNestedObject(details)}</DialogContent>
    </Dialog>
  );
};
