import React from 'react';
import { Button } from '@mui/material';

interface StyleButtonProps {
  children: any;
  onClick: any;
  inverted?: boolean;
  style?: any;
  disabled?: boolean;
}

export const StyledButton = ({
  children,
  inverted = false,
  onClick,
  style,
  disabled,
}: StyleButtonProps) => (
  <Button
    onClick={onClick}
    sx={{
      height: 40,
      textTransform: 'none',
      // @TODO: find hex value
      background: inverted ? '#fff' : '#181818',
      color: inverted ? '#000' : '#fff',
      '&:hover': {
        backgroundColor: inverted ? 'light-gray' : '#353535',
      },
      ':disabled': {
        background: '#ccc',
      },
      padding: '15px',
      ...style,
    }}
    disabled={disabled}
  >
    {children}
  </Button>
);
