import { Box, Typography } from '@mui/material';

type RowPropsType = {
  field: string;
  value: string | number;
};

export const Row = ({ field, value }: RowPropsType) => {
  return (
    <Box
      p={2.5}
      mb={2}
      bgcolor="#D3D3D359"
      borderRadius={2}
      justifyContent="space-between"
      display="flex"
      flexDirection="row"
    >
      <Typography>{field}:</Typography>
      <Typography>{value ? value : '-'}</Typography>
    </Box>
  );
};
