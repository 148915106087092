import * as Yup from 'yup';
import { parse, isValid, isFuture } from 'date-fns';

const parseDateString = (value: any, originalValue: string | Date) => {
  if (!originalValue) return null;

  if (originalValue instanceof Date) {
    return originalValue;
  }

  if (typeof originalValue === 'string' && originalValue.trim() !== '') {
    const parsedDate = parse(originalValue, 'MM/dd/yyyy', new Date());
    return isValid(parsedDate) ? parsedDate : new Date('Invalid Date');
  }

  return null;
};

export const validationSchema = Yup.object({
  owner1FirstName: Yup.string().required('Required'),
  owner1LastName: Yup.string().required('Required'),
  owner1DateOfBirth: Yup.mixed()
    .transform(parseDateString)
    .test(
      'is-valid-date',
      'Please enter a valid date in MM/DD/YYYY format',
      (value) =>
        value === null ||
        (value instanceof Date && isValid(value) && !isNaN(value.getTime())),
    )
    .test(
      'is-past',
      'Date of birth must be in the past',
      (value) => value === null || (value instanceof Date && !isFuture(value)),
    )
    .required('Required'),

  owner1SSN: Yup.string().required('Required'),
  owner1AddressLine1: Yup.string().required('Required'),
  owner1AddressLine2: Yup.string(),
  owner1City: Yup.string().required('Required'),
  owner1State: Yup.string().nullable().required('Required'),
  owner1Zip: Yup.string()
    .required('Required')
    .test('valid-zip', 'Invalid USA Zip Code', (value) => {
      if (!value) return true;
      return /^\d{5}(-\d{4})?$/.test(value);
    }),
  owner1Country: Yup.string().required('Required'),
  owner1Email: Yup.string().email('Invalid email').required('Required'),
  owner1MobilePhoneNumber: Yup.string()
    .nullable()
    .required('Required')
    .test('is-valid-phone', 'Invalid phone number', (value) => {
      if (!value) return true;
      return /^\d{3}-\d{3}-\d{4}$/.test(value);
    }),
  owner1OwnershipPercentage: Yup.string()
    .required('Required')
    .test('is-minimum-ownership', 'Ownership must be at least 25%', (value) => {
      return Number(value) >= 25;
    }),
  addAnotherOwner: Yup.boolean(),
  owner2FirstName: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  owner2LastName: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  owner2DateOfBirth: Yup.mixed()
    .transform(parseDateString)
    .test(
      'is-valid-date',
      'Please enter a valid date in MM/DD/YYYY format',
      (value) =>
        value === null ||
        (value instanceof Date && isValid(value) && !isNaN(value.getTime())),
    )
    .test(
      'is-past',
      'Date of birth must be in the past',
      (value) => value === null || (value instanceof Date && !isFuture(value)),
    )
    .when('addAnotherOwner', {
      is: true,
      then: Yup.mixed().required('Required'),
      otherwise: Yup.mixed().nullable(),
    }),
  owner2SSN: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  owner2AddressLine1: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  owner2AddressLine2: Yup.string(),
  owner2City: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  owner2State: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().nullable().required('Required'),
  }),
  owner2Zip: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string()
      .required('Required')
      .test('valid-zip', 'Invalid USA Zip Code', (value) => {
        if (!value) return true;
        return /^\d{5}(-\d{4})?$/.test(value);
      }),
  }),
  owner2Country: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  owner2Email: Yup.string()
    .email('Invalid email')
    .when('addAnotherOwner', {
      is: true,
      then: Yup.string().required('Required'),
    }),
  owner2MobilePhoneNumber: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string()
      .nullable()
      .required('Required')
      .test('is-valid-phone', 'Invalid phone number', (value) => {
        if (!value) return true;
        return /^\d{3}-\d{3}-\d{4}$/.test(value);
      }),
  }),
  owner2OwnershipPercentage: Yup.string().when('addAnotherOwner', {
    is: true,
    then: Yup.string()
      .required('Required')
      .test(
        'is-minimum-ownership',
        'Ownership must be at least 25%',
        (value) => {
          return Number(value) >= 25;
        },
      ),
  }),
  authorizeCreditReview: Yup.boolean().oneOf(
    [true],
    'Authorization is required',
  ),
});
