import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import debounce from 'lodash/debounce';
import * as service from './service';

interface InputUSDOTProps extends Omit<TextFieldProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
  validateField: (field: string) => Promise<void> | Promise<string | undefined>;
  onLoadingChange?: (isLoading: boolean) => void;
}

export function InputUSDOT(props: InputUSDOTProps) {
  const {
    value,
    onChange,
    onBlur,
    error,
    helperText,
    name,
    setFieldValue,
    setFieldTouched,
    validateField,
    onLoadingChange,
    ...textFieldProps
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\D/g, '').slice(0, 8);
    onChange(inputValue);
  };

  const validateUSDOTNumber = React.useCallback(
    debounce(async (usDotNumber: string) => {
      if (usDotNumber.length >= 5 && usDotNumber.length <= 8) {
        onLoadingChange?.(true);
        try {
          const result = await service.validateUSDOT(usDotNumber);
          if (result && result.carrier) {
            const { carrier, mcNumber } = result;

            const countryMap: { [key: string]: string } = {
              US: 'USA',
              CA: 'Canada',
              MX: 'Mexico',
            };

            setFieldValue('businessName', carrier.legalName || '');
            setFieldValue(
              'subsidiary',
              carrier.dbaName || carrier.legalName || '',
            );
            setFieldValue('numberOfTrucks', carrier.totalPowerUnits || '');
            setFieldValue('businessAddress1', carrier.phyStreet || '');
            setFieldValue('businessCity', carrier.phyCity || '');
            setFieldValue('businessState', carrier.phyState || '');
            setFieldValue('businessZip', carrier.phyZipcode || '');
            setFieldValue(
              'businessCountry',
              countryMap[carrier.phyCountry] || '',
            );
            setFieldValue('mcNumber', mcNumber || '');

            if (carrier.ein) {
              setFieldValue(
                'federalIdNumber',
                carrier.ein.toString().replace(/^(\d{2})(\d{7})$/, '$1-$2'),
              );
            }

            if (carrier.safetyRatingDate) {
              const yearsInBusiness = Math.floor(
                (new Date().getTime() -
                  new Date(carrier.safetyRatingDate).getTime()) /
                  (365 * 24 * 60 * 60 * 1000),
              );
              setFieldValue('yearsInBusiness', yearsInBusiness.toString());
            }

            const fieldsToUpdate = [
              'subsidiary',
              'businessAddress1',
              'businessCity',
              'businessState',
              'businessZip',
              'businessCountry',
              'mcNumber',
              'federalIdNumber',
              'yearsInBusiness',
            ];

            fieldsToUpdate.forEach((field) => {
              setFieldTouched(field, false);
              validateField(field);
            });
          }
        } catch (error) {
          console.error('Error validating USDOT:', error);
        } finally {
          onLoadingChange?.(false);
        }
      }
    }, 300),
    [setFieldValue, setFieldTouched, validateField, onLoadingChange],
  );

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    setFieldTouched(name, true, false);
    await validateField(name);
    validateUSDOTNumber(value);
  };

  return (
    <TextField
      {...textFieldProps}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      helperText={helperText}
      inputProps={{
        ...textFieldProps.inputProps,
        maxLength: 8,
      }}
      placeholder="USDOT Number (5-8 digits)"
    />
  );
}
