import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { listDriversService } from '@/fleet/services/drivers';

import SuccessAlert from '@/fleet/components/common/alerts/success';
import ErrorAlert from '@/fleet/components/common/alerts/error';
import AddDriverModal from '@/fleet/components/views/drivers/AddDriverModal';
import { StatusPill } from '../../components/common/StatusPill';

interface StyleButtonProps {
  children: any;
  onClick: any;
  inverted?: boolean;
  style?: any;
  disabled?: boolean;
}

// @TODO: find a standard place for this
const StyledButton = ({
  children,
  inverted = false,
  onClick,
  style,
  disabled,
}: StyleButtonProps) => (
  <Button
    onClick={onClick}
    sx={{
      height: 40,
      textTransform: 'none',
      // @TODO: find hex value
      background: inverted ? '#fff' : '#181818',
      color: inverted ? '#000' : '#fff',
      '&:hover': {
        backgroundColor: inverted ? 'light-gray' : '#353535',
      },
      ':disabled': {
        background: '#ccc',
      },
      padding: '15px',
      ...style,
    }}
    disabled={disabled}
  >
    {children}
  </Button>
);

export function Drivers() {
  const { carrierUuid } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showAddDriverModal, setShowAddDriverModal] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    listDriversService(carrierUuid).then((response) => {
      setData((response as AxiosResponse<any>).data.drivers);
      setLoading(false);
    });
  }, [showSuccessAlert]);

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Driver ID',
      name: 'driverExternalId',
      options: {
        filter: false,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'phoneNumber',
      label: 'Phone',
      options: {
        filter: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
      },
    },
    {
      name: 'enabled',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'checkbox',
        customFilterListOptions: {
          render: (v) => `Status: ${v ? 'Active' : 'Inactive'}`,
        },
        filterOptions: {
          renderValue: (v) => (v ? 'Active' : 'Inactive'),
        },
        // @ts-expect-error: TS2322 because value is of boolean and not string type
        filterList: [true],
        customBodyRender: (value) => <StatusPill active={Boolean(value)} />,
      },
    },
    {
      name: 'uuid',
      label: ' ',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (driverUuid) => {
          return (
            <Button
              size="small"
              sx={{
                padding: 0,
                textTransform: 'none',
                color: '#181818',
                '&:hover': {
                  color: '#181818',
                  background: 'none',
                },
              }}
              onClick={() => {
                const driver = data.filter((x: any) => x.uuid === driverUuid);
                navigate(`/carriers/${carrierUuid}/drivers/${driverUuid}`, {
                  state: driver,
                });
              }}
            >
              <EditIcon />
            </Button>
          );
        },
      },
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Box
        marginBottom={4}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <StyledButton
          style={{ ml: 2 }}
          onClick={() => setShowAddDriverModal(true)}
        >
          Add Driver
        </StyledButton>
      </Box>

      <MUIDataTable
        columns={columns}
        data={data}
        title="Manage Drivers"
        options={{
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          filter: true,
          download: false,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],

          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No drivers found'
              ),
            },
          },
        }}
      />
      {showAddDriverModal && (
        <AddDriverModal
          handleClose={() => setShowAddDriverModal(false)}
          setShowSuccessAlert={setShowSuccessAlert}
          setShowErrorAlert={setShowErrorAlert}
          setAlertMessage={setAlertMessage}
        />
      )}
      <SuccessAlert
        message="Added driver successfully!"
        open={showSuccessAlert}
        handleClose={() => setShowSuccessAlert(false)}
      />
      <ErrorAlert
        message={`An error occurred when trying to add driver. Please try again later. ${alertMessage}`}
        open={showErrorAlert}
        handleClose={() => setShowErrorAlert(false)}
      />
    </div>
  );
}
