import { Box, Typography } from '@mui/material';

export const LimitHeaderRow = () => {
  return (
    <Box
      mt={4}
      mb={1}
      justifyContent="space-between"
      display="flex"
      flexDirection="row"
    >
      <Typography textTransform="uppercase" fontWeight={600} ml={1} width="25%">
        LIMITS
      </Typography>
      <Typography
        sx={{ textDecoration: 'underline', width: '25%', textAlign: 'end' }}
      >
        Limit
      </Typography>
      <Typography
        sx={{
          textDecoration: 'underline',
          width: '25%',
          textAlign: 'end',
          mr: 1,
        }}
      >
        Balance
      </Typography>
      <Typography
        sx={{
          textDecoration: 'underline',
          width: '25%',
          textAlign: 'end',
          mr: 1,
        }}
      >
        Remaining
      </Typography>
    </Box>
  );
};
