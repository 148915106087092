import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { useParams } from 'react-router-dom';
import { listMerchantLocationsServiceByCarrier } from '@/fleet/services/shared';
import { StatusPill } from '@/fleet/components/common/StatusPill';

const columns: MUIDataTableColumnDef[] = [
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Merchant',
    name: 'merchant.chainName',
  },
  {
    label: 'Address',
    name: 'address.street1',
    options: {
      customBodyRender: (value) => {
        return <Box textTransform="uppercase">{value}</Box>;
      },
    },
  },
  {
    label: 'City',
    name: 'address.city',
    options: {
      customBodyRender: (value) => {
        return <Box textTransform="uppercase">{value}</Box>;
      },
    },
  },
  {
    label: 'State',
    name: 'address.region',
    options: {
      customBodyRender: (value) => {
        return <Box textTransform="uppercase">{value}</Box>;
      },
    },
  },
  {
    label: 'Dispensers',
    name: 'numberOfDispensers',
  },
  {
    label: 'Status',
    name: 'active',
    options: {
      customBodyRender: (value) => {
        if (value) {
          return <StatusPill active />;
        }
        return <StatusPill active={false} />;
      },
    },
  },
];

export function Stations() {
  const [loading, setLoading] = useState(true);
  const [stations, setStations] = useState();
  const { carrierUuid } = useParams();

  useEffect(() => {
    if (!carrierUuid) {
      return;
    }

    listMerchantLocationsServiceByCarrier(carrierUuid)
      .then((response) => {
        const { locations } = response.data;
        setStations(locations);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <MUIDataTable
        columns={columns}
        data={stations || []}
        title="Stations"
        options={{
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          filter: false,
          download: false,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],
          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No stations found'
              ),
            },
          },
        }}
      />
    </div>
  );
}
