import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import { entries } from 'lodash';
import toast from 'react-hot-toast';

import { setLimitsService } from '@/fleet/services/carriers';
import {
  LIMIT_GROUP_OPTIONS,
  LIMIT_OPTIONS,
  LimitValuesType,
  PRODUCTS,
  TODAY,
} from './constants';
import { limitValidationSchema } from './validations';

interface SubmitValuesType {
  amount: string | null;
  asOfDate: string;
  carrierUuid: string;
  productCategory: string;
  quantity: string | null;
}
interface LimitSettingsType {
  limitFormValues: LimitValuesType;
  carrierUuid: string | undefined;
  editingLimits: boolean;
  setEditingLimits: Dispatch<SetStateAction<boolean>>;
  setLimitFormValues: React.Dispatch<
    React.SetStateAction<{
      limitType: string;
      tractorDiesel: string;
      reeferDiesel: string;
      def: string;
      oil: string;
      additives: string;
      merchandise: string;
      cashAdvance: string;
    }>
  >;
}

const LimitSettings = ({
  limitFormValues,
  carrierUuid,
  editingLimits,
  setEditingLimits,
  setLimitFormValues,
}: LimitSettingsType) => {
  return (
    <Formik
      initialValues={limitFormValues}
      enableReinitialize
      validationSchema={limitValidationSchema}
      onSubmit={async (values) => {
        if (!carrierUuid) {
          return;
        }
        const submitValues: SubmitValuesType[] = [];
        /* maps through formik values and looks to see if the value matches
        the values in PRODUCTS array. If there is a match, we push a new object
        into the submitValues array. */
        await entries(values).map((value) => {
          const amount = value[1];
          PRODUCTS.map((product) => {
            if (value[0] === product.value) {
              if (product.isFuel) {
                submitValues.push({
                  amount: values.limitType === 'amount' ? amount : null,
                  asOfDate: TODAY,
                  carrierUuid,
                  productCategory: product.product,
                  quantity: values.limitType === 'gallons' ? amount : null,
                });
              } else {
                submitValues.push({
                  amount,
                  asOfDate: TODAY,
                  carrierUuid,
                  productCategory: product.product,
                  quantity: null,
                });
              }
            }
          });
        });
        toast
          .promise(setLimitsService(submitValues, carrierUuid), {
            loading: 'Saving...',
            success: 'Limit settings saved!',
            error: 'Unable to save limit settings. Please try again later.',
          })
          .then(() => {
            setLimitFormValues(values);
          });
        setEditingLimits(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            border={1}
            borderColor="#D4D4D4"
            bgcolor="#F8F8FA"
            fontWeight={600}
            fontSize={14}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Box>LIMITS</Box>
            <Box>
              {editingLimits && (
                <Button
                  sx={{
                    width: 100,
                    paddingX: 3,
                    paddingY: 1,
                    mr: 1,
                    bgcolor: 'black',
                    color: 'white',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                  }}
                  onClick={() => {
                    dirty ? handleSubmit() : toast('No changes made');
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                sx={{
                  width: 100,
                  paddingX: 3,
                  paddingY: 1,
                  border: 1,
                  borderColor: editingLimits ? 'gray' : null,
                  bgcolor: editingLimits ? 'white' : 'black',
                  color: editingLimits ? 'black' : 'white',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'gray',
                  },
                }}
                onClick={() => {
                  if (editingLimits) {
                    setEditingLimits(false);
                    setFieldValue('limitType', '');
                    resetForm({ values: limitFormValues });
                  } else {
                    setEditingLimits(true);
                  }
                }}
              >
                {editingLimits ? 'Cancel' : 'Edit'}
              </Button>
            </Box>
          </Box>
          {LIMIT_OPTIONS.map((option, groupIndex) => {
            const lastLimitGroup = groupIndex === LIMIT_OPTIONS.length - 1;
            return (
              <Box key={option.group}>
                <Box
                  display="flex"
                  alignItems="center"
                  p={2}
                  border={1}
                  borderTop={0}
                  borderColor="#D4D4D4"
                  height="70px"
                >
                  <Box width="50%" fontSize={14} fontWeight={600}>
                    {option.group}
                  </Box>
                  {option.group === LIMIT_GROUP_OPTIONS.FUEL && (
                    <Box minWidth="300px">
                      <FormControl disabled={!editingLimits}>
                        <RadioGroup
                          row
                          name="limitType"
                          onChange={(event) => {
                            setFieldValue('limitType', event.target.value);
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Box>Dollar Amount</Box>
                            <Radio
                              checked={values.limitType === 'amount'}
                              onChange={handleChange}
                              value="amount"
                              name="radio-buttons"
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Box>Gallons</Box>
                            <Radio
                              checked={values.limitType === 'gallons'}
                              onChange={handleChange}
                              value="gallons"
                              name="radio-buttons"
                            />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                      <FormHelperText
                        error={touched.limitType && !!errors.limitType}
                      >
                        {touched.limitType && errors.limitType}
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
                {option.options.map((o, index) => {
                  const isLastRow = index === option.options.length - 1;
                  return (
                    <Box
                      key={o.value}
                      display="flex"
                      alignItems="center"
                      py={2}
                      px={4}
                      fontSize={14}
                      border={1}
                      borderTop={0}
                      borderColor="#D4D4D4"
                      sx={
                        isLastRow && lastLimitGroup
                          ? {
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }
                          : null
                      }
                    >
                      <Box width="50%">{o.label}</Box>
                      <Box width="50%">
                        <TextField
                          hiddenLabel
                          disabled={!editingLimits}
                          variant="outlined"
                          name={`${o.value}`}
                          onChange={handleChange}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore - TODO: Address indexing type safety for LimitValuesType
                          value={values[o.value]}
                          size="small"
                          sx={{
                            width: '100%',
                          }}
                          InputProps={{
                            startAdornment:
                              (values.limitType === 'amount' &&
                                option.group === LIMIT_GROUP_OPTIONS.FUEL) ||
                              option.group === LIMIT_GROUP_OPTIONS.NON_FUEL ? (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ) : null,
                            endAdornment:
                              values.limitType === 'gallons' &&
                              option.group === LIMIT_GROUP_OPTIONS.FUEL ? (
                                <InputAdornment position="end">
                                  gal
                                </InputAdornment>
                              ) : null,
                          }}
                        />
                        <FormHelperText
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore - TODO: Address indexing type safety for LimitValuesType
                          error={touched[o.value] && !!errors[o.value]}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore - TODO: Address indexing type safety for LimitValuesType */}
                          {touched[o.value] && errors[o.value]}
                        </FormHelperText>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </form>
      )}
    </Formik>
  );
};

export default LimitSettings;
