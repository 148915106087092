import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

export default function Success() {
  return (
    <Grid item container direction="column" xs={12}>
      <Typography variant="h5" align="center" sx={{ py: 4 }}>
        Thank you for your application!
      </Typography>
      <Typography variant="h6" align="center">
        You will receive an email regarding your application&apos;s status in
        3-5 days.
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', pt: 4 }}
        justifyContent="center"
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: '#181818 !important',
            color: '#181818',
            width: '20%',
            fontWeight: '700',
            '&:hover': {
              background: '#ffff',
              color: '#181818',
            },
            p: 2,
          }}
          href="https://onrampcard.com/"
        >
          Home
        </Button>
      </Box>
    </Grid>
  );
}
