import { format } from 'date-fns';
import { Grid, Paper, Typography, Box, Divider } from '@mui/material';
import * as step0 from '../step0';
import * as step1 from '../step1';
import * as step2 from '../step2';

type FormValues = typeof step0.initialValues &
  typeof step1.initialValues &
  typeof step2.initialValues;

interface ConfirmationProps {
  values: FormValues;
}

export function Confirmation({ values }: ConfirmationProps) {
  const formatCurrency = (value: string) => {
    if (!value) return '$0';

    // Remove any non-digit characters
    const numericValue = value.replace(/\D/g, '');

    // Convert to number and format
    const number = parseInt(numericValue, 10);
    return `$${number.toLocaleString('en-US')}`;
  };

  const formatDate = (date: Date | null) => {
    return date ? format(date, 'MM/dd/yyyy') : 'Not provided';
  };

  return (
    <Paper variant="outlined" sx={{ p: 3, my: 3 }}>
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>
          <strong>Confirmation</strong>
        </Typography>
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          <strong>Business Contact Information</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>First Name:</strong> {values.firstName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Last Name:</strong> {values.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Email:</strong> {values.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Phone:</strong> {values.phone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Business Name:</strong> {values.businessName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Number of Trucks:</strong> {values.numberOfTrucks}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Authorized Officer:</strong>{' '}
              {values.authorizedOfficer ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          <strong>Business Details</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Subsidiary:</strong> {values.subsidiary}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>State of Incorporation:</strong>{' '}
              {values.stateOfIncorporation}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Federal ID Number:</strong> {values.federalIdNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Industry Type:</strong> {values.industryType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Type of Business:</strong> {values.typeOfBusiness}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Years in Business:</strong> {values.yearsInBusiness}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Annual Revenue:</strong>{' '}
              {formatCurrency(values.annualRevenue)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Weekly Fuel Spend:</strong>{' '}
              {formatCurrency(values.weeklyFuelSpend)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Business Address 1:</strong> {values.businessAddress1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Business Address 2:</strong> {values.businessAddress2}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>City:</strong> {values.businessCity}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>State:</strong> {values.businessState}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Zip:</strong> {values.businessZip}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Country:</strong> {values.businessCountry}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Business Phone Number:</strong>{' '}
              {values.businessPhoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>US DOT Number:</strong> {values.usDotNumber}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          <strong>Owner Information</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>First Name:</strong> {values.owner1FirstName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Last Name:</strong> {values.owner1LastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Owner 1 Date of Birth:</strong>{' '}
              {formatDate(values.owner1DateOfBirth)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>SSN:</strong> {values.owner1SSN}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Address Line 1:</strong> {values.owner1AddressLine1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Address Line 2:</strong> {values.owner1AddressLine2}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>City:</strong> {values.owner1City}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>State:</strong> {values.owner1State}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Zip:</strong> {values.owner1Zip}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Country:</strong> {values.owner1Country}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Email:</strong> {values.owner1Email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Mobile Phone Number:</strong>{' '}
              {values.owner1MobilePhoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Ownership Percentage:</strong>{' '}
              {values.owner1OwnershipPercentage}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {values.addAnotherOwner && (
        <>
          <Divider sx={{ mb: 3 }} />
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              <strong>Additional Owner Information</strong>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>First Name:</strong> {values.owner2FirstName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Last Name:</strong> {values.owner2LastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Owner 2 Date of Birth:</strong>{' '}
                  {formatDate(values.owner2DateOfBirth)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>SSN:</strong> {values.owner2SSN}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Address Line 1:</strong> {values.owner2AddressLine1}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Address Line 2:</strong> {values.owner2AddressLine2}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>City:</strong> {values.owner2City}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>State:</strong> {values.owner2State}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Zip:</strong> {values.owner2Zip}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Country:</strong> {values.owner2Country}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Email:</strong> {values.owner2Email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Mobile Phone Number:</strong>{' '}
                  {values.owner2MobilePhoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Ownership Percentage:</strong>{' '}
                  {values.owner2OwnershipPercentage}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Divider sx={{ mb: 3 }} />

      <Box mb={3}>
        <Typography variant="body1">
          <strong>Authorize Credit Review:</strong>{' '}
          {values.authorizeCreditReview ? 'Yes' : 'No'}
        </Typography>
      </Box>
    </Paper>
  );
}
