import * as React from 'react';
import { Formik, Form } from 'formik';

import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
} from '@mui/material';

import ConfirmStep from './components/Confirmation/ConfirmDetailsStep';
import BusinessInformationForm from './components/Form/BusinessDetailsForm';
import FleetInformation from './components/Form/FleetDetailsForm';
import OwnerInformationForm from './components/Form/OwnerDetailsForm';
import Success from './components/Success';
import creditApplicationModel from './components/Model/creditApplicationModel';
import initialValues from './components/Model/initialValues';
import validationSchema from './components/Model/validationSchema';
import { createCreditApplicationService } from './services/creditApplication';

const { formId, formField } = creditApplicationModel;

const steps = [
  'Business Details',
  'Fleet Details',
  'Owner Details',
  'Confirmation',
];

const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <BusinessInformationForm formField={formField} />;
    case 1:
      return <FleetInformation formField={formField} />;
    case 2:
      return <OwnerInformationForm formField={formField} />;
    case 3:
      return <ConfirmStep />;
    default:
      throw new Error('Unknown step');
  }
};

export function Card() {
  const [activeStep, setActiveStep] = React.useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  async function submitForm(values: any, actions: any) {
    actions.setSubmitting(true);
    createCreditApplicationService(values).then(() => {
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
    });
  }

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        minWidth: '55%',
        maxWidth: '900px',
        my: { xs: 3, md: 6 },
        p: { xs: 2, md: 3 },
      }}
    >
      {activeStep === steps.length ? (
        <Success />
      ) : (
        <>
          <Box sx={{ my: 5 }}>
            <Typography component="h1" variant="h4" align="center">
              Fuel Credit Application
            </Typography>
          </Box>
          <Stepper activeStep={activeStep} sx={{ py: 1 }} alternativeLabel>
            {steps.map((label) => (
              <Step
                key={label}
                sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: '#181818', // circle color (COMPLETED)
                  },
                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                    {
                      color: '#181818', // Just text label (COMPLETED)
                    },
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#FFF800', // circle color (ACTIVE)
                  },
                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                    {
                      color: '#181818', // Just text label (ACTIVE)
                    },
                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                    fill: '#181818', // circle's number (ACTIVE)
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form id={formId}>
                  {handleSteps(activeStep)}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      sx={{
                        background: '#181818',
                        color: '#fff',
                        width: '100%',
                        fontWeight: '700',
                        '&:hover': {
                          background: '#FFF800',
                          color: '#181818',
                        },
                        p: 2,
                      }}
                    >
                      {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ p: 2, width: '100%' }}
                    >
                      Back
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Paper>
  );
}
