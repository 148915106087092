import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';

export function Root() {
  return (
    <>
      <Outlet />
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#181818',
            color: '#fff',
          },
        }}
      />
    </>
  );
}
