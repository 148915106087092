import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  CircularProgress,
  Dialog,
  Input,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';
import SuccessAlert from '@/fleet/components/common/alerts/success';
import ErrorAlert from '@/fleet/components/common/alerts/error';
import {
  activateDriverService,
  deactivateDriverService,
  listDriversService,
  setPasswordService,
  updateDriver,
} from '@/fleet/services/drivers';
import { Mixpanel } from '@/fleet/config/mixpanel';
import {
  cancelButtonStyle,
  submitButtonStyle,
} from '@/fleet/components/common/styles';
import { getBalanceService, getLimitsService } from '../../services/carriers';
import { Row } from '../../components/views/driver/Row';
import {
  TODAY,
  DRIVER_FUEL_PRODUCT_VALUES,
  DRIVER_NON_FUEL_PRODUCT_VALUES,
} from '../../components/views/settings/constants';
import toast from 'react-hot-toast';
import { LimitRow } from '../../components/views/driver/LimitRow';
import { LimitHeaderRow } from '../../components/views/driver/LimitHeaderRow';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

interface DriverBalance {
  [key: string]: { value: string };
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('A valid email is required')
    .required('A valid email is required'),
});

export function Driver() {
  const { driverUuid, carrierUuid } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [identityUuid, setIdentityUuid] = useState('');
  const [driverEnabled, setDriverEnabled] = useState(true);
  const [message, setMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmDriverState, setShowConfirmDriverState] = useState(false);
  const [driverLimits, setDriverLimits] = useState<any>();
  const [driverBalances, setDriverBalances] = useState<DriverBalance>();
  const [editFields, setEditFields] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const handleSubmitDriverChanges = () => {};

  const handleToggleEditFields = () => {
    setEditFields(!editFields);
  };

  const handleSubmitPassword = () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords must match.');
      return;
    }
    if (password === '' && confirmPassword === '') {
      setErrorMessage('Invalid password.');
      return;
    }
    if (password.length < 4) {
      setErrorMessage('Password must be at least 4 characters long.');
      return;
    }

    const passwordValues = {
      password,
      temporary: true,
      entityUuid: driverUuid,
      identityUuid,
    };

    setPasswordService(passwordValues)
      .then((response) => {
        if (response.status === 200) {
          setMessage('Password reset successfully!');
          setOpenSuccess(true);
          Mixpanel.track('password_reset', omit(passwordValues, 'password'));
        } else {
          setMessage(
            'Unable to reset password. Please try again later or contact support for assistance.',
          );
          setOpenError(true);
        }
        setShowPasswordFields(false);
      })
      .catch(() => {
        setMessage(
          'Unable to reset password. Please try again later or contact support for assistance.',
        );
        setShowPasswordFields(false);
        setOpenError(true);
      });
  };

  const handleDeactivateDriver = () => {
    deactivateDriverService(carrierUuid, driverUuid, identityUuid).then(
      (response) => {
        if (response.status === 200) {
          setOpenSuccess(true);
          setMessage('Driver was deactivated.');
          setDriverEnabled(false);
        } else {
          setOpenError(true);
          setMessage(
            'There was an issue deactivating driver, contact support.',
          );
        }
      },
    );
    setShowConfirmDriverState(false);
  };

  const handleActivateDriver = () => {
    activateDriverService(carrierUuid, driverUuid, identityUuid)
      .then((response) => {
        if (response.status === 200) {
          setOpenSuccess(true);
          setMessage('Driver was activated.');
          setDriverEnabled(true);
        } else {
          setOpenError(true);
          setMessage('There was an issue activating driver, contact support.');
        }
      })
      .catch(() => {
        setOpenError(true);
        setMessage('There was an issue activating driver, contact support.');
      });
    setShowConfirmDriverState(false);
  };

  useEffect(() => {
    setLoading(true);
    listDriversService(carrierUuid).then((response) => {
      const data = (response as AxiosResponse<any>).data.drivers.filter(
        (r: any) => r.uuid === driverUuid,
      )[0];

      setName(`${data.firstName} ${data.lastName}`);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setIdentityUuid(data.identityUuid);
      setDriverEnabled(data.enabled);
      setLoading(false);
    });
  }, [reload]);

  useEffect(() => {
    if (!driverUuid || !carrierUuid) {
      return;
    }

    const getLimits = async () => {
      getLimitsService(carrierUuid, TODAY).then(async (response) => {
        const newProducts = [];
        const data = response.data.limits;
        const hasAmountValue = !!Number(data[0].amount);

        if (hasAmountValue) {
          newProducts.push({ limitType: 'amount' });
        } else {
          newProducts.push({ limitType: 'gallons' });
        }

        await data.map((limit: any) => {
          Object.keys(DRIVER_FUEL_PRODUCT_VALUES).map((product) => {
            if (limit.productCategory === product) {
              const keyValue =
                DRIVER_FUEL_PRODUCT_VALUES[
                  product as keyof typeof DRIVER_FUEL_PRODUCT_VALUES
                ];
              const amountValue =
                Number(limit.amount) === 0
                  ? {
                      limitValue: limit.quantity,
                      balanceField: `${keyValue}${'QtyBalance'}`,
                    }
                  : {
                      limitValue: limit.amount,
                      balanceField: `${keyValue}${'Balance'}`,
                    };
              newProducts.push({ [keyValue]: amountValue });
            }
          });
        });

        await data.map((limit: any) => {
          Object.keys(DRIVER_NON_FUEL_PRODUCT_VALUES).map((product) => {
            if (limit.productCategory === product) {
              const keyValue =
                DRIVER_NON_FUEL_PRODUCT_VALUES[
                  product as keyof typeof DRIVER_NON_FUEL_PRODUCT_VALUES
                ];
              const amountValue =
                Number(limit.amount) === 0
                  ? {
                      limitValue: limit.quantity,
                      balanceField: `${keyValue}${'QtyBalance'}`,
                    }
                  : {
                      limitValue: limit.amount,
                      balanceField: `${keyValue}${'Balance'}`,
                    };
              newProducts.push({ [keyValue]: amountValue });
            }
          });
        });

        setDriverLimits(newProducts);
      });
    };

    const getBalanceInformation = async () => {
      await getBalanceService(driverUuid)
        .then((response: any) => {
          if (response.isAxiosError) {
            toast.error(
              `Failed to retrieve balance\n Error detail: ${response.response.data.message}`,
            );
            return;
          }
          setDriverBalances(response.data.balances);
        })
        .catch((error: any) =>
          toast.error(
            `Failed to retrieve balance\n Error detail: ${error.message}`,
          ),
        );
    };
    getBalanceInformation();
    getLimits();
  }, [driverUuid]);

  const limitTotals = useMemo(() => {
    const combinedArray: { [key: string]: string }[] = [];

    if (driverBalances && driverLimits) {
      driverLimits.forEach((limitItem: { [x: string]: any }) => {
        const keys = Object.keys(limitItem);

        const balanceField = limitItem[keys[0]].balanceField;
        const limitValue = !!parseFloat(limitItem[keys[0]].limitValue);

        if (
          Object.prototype.hasOwnProperty.call(driverBalances, balanceField) &&
          limitValue
        ) {
          const combinedObject = {
            ...limitItem[keys[0]],
            ...driverBalances[balanceField],
          };
          combinedArray.push(combinedObject);
        }
      });
    }
    return combinedArray;
  }, [driverBalances, driverLimits]);

  return (
    <Paper square elevation={1} sx={{ p: 3 }}>
      <Box>
        <Box>
          <Button
            size="small"
            variant="text"
            sx={{ color: '#000', backgroundColor: '#fff' }}
            onClick={() => navigate(`/carriers/${carrierUuid}/drivers`)}
          >
            <ArrowBackIcon />
            <Typography variant="h6" sx={{ textTransform: 'none' }}>
              Drivers
            </Typography>
          </Button>
          {!editFields && (
            <Button
              variant="contained"
              sx={{
                ml: 1,
              }}
              onClick={handleToggleEditFields}
              color="primary"
            >
              Edit Driver
            </Button>
          )}
        </Box>

        <br />
        {loading ? (
          <CircularProgress
            sx={{
              color: '#181818',
            }}
          />
        ) : (
          <>
            {editFields ? (
              <Formik
                initialValues={{
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                  updateDriver(values, carrierUuid, driverUuid).then(() => {
                    handleToggleEditFields();
                    setReload(!reload);
                  });
                }}
              >
                {({ values, handleChange, handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Box m={1} mt={2} width={700}>
                        <Box
                          py={1}
                          px={1.5}
                          mb={2}
                          bgcolor="#D3D3D359"
                          borderRadius={2}
                          justifyContent="space-between"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography p={1}>Name:</Typography>
                          <Box>
                            <Input
                              size="small"
                              onChange={handleChange}
                              defaultValue={firstName}
                              disableUnderline
                              value={values.firstName}
                              name="firstName"
                              placeholder="First"
                              sx={{
                                height: 10,
                                backgroundColor: 'white',
                                borderRadius: 2,
                                paddingX: 2,
                                paddingY: 3,
                                marginRight: 1,
                              }}
                              inputProps={{
                                style: {
                                  textAlign: 'right',
                                },
                              }}
                            />
                            <Input
                              size="small"
                              onChange={handleChange}
                              defaultValue={lastName}
                              disableUnderline
                              value={values.lastName}
                              name="lastName"
                              placeholder="Last"
                              sx={{
                                height: 10,
                                backgroundColor: 'white',
                                borderRadius: 2,
                                paddingX: 2,
                                paddingY: 3,
                              }}
                              inputProps={{
                                style: {
                                  textAlign: 'right',
                                },
                              }}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                          <ErrorMessage name={`${firstName} || ${lastName}`}>
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Box>
                      </Box>
                      <Box m={1} mt={2} width={700}>
                        <Box
                          py={1}
                          px={1.5}
                          mb={2}
                          bgcolor="#D3D3D359"
                          borderRadius={2}
                          justifyContent="space-between"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography p={1}>Email:</Typography>
                          <Input
                            size="small"
                            onChange={handleChange}
                            defaultValue={email}
                            value={values.email}
                            name="email"
                            disableUnderline
                            sx={{
                              height: 10,
                              width: '60%',
                              backgroundColor: 'white',
                              borderRadius: 2,
                              paddingX: 2,
                              paddingY: 3,
                            }}
                            inputProps={{
                              style: {
                                textAlign: 'right',
                              },
                            }}
                          />
                        </Box>
                        <Box sx={{ ml: 2 }}>
                          <ErrorMessage name="email">
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{ ml: 1 }}
                            onClick={handleToggleEditFields}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              ml: 1,
                            }}
                            onClick={handleSubmitDriverChanges}
                            color="primary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Box m={1} mt={2} width={700}>
                <Row field="Name" value={name} />
                <Row field="Email" value={email} />
              </Box>
            )}
            <Box m={1} mt={2} width={700}>
              {!isEmpty(limitTotals) ? <LimitHeaderRow /> : null}
              {limitTotals?.map((limit) => {
                const field = limit?.balanceField;
                const limitValue = parseFloat(limit?.limitValue);
                const balanceValue = parseFloat(limit?.value);
                const remainingBalance = limitValue - balanceValue;
                return (
                  <LimitRow
                    key={field}
                    field={field}
                    balanceValue={balanceValue}
                    limitValue={limitValue}
                    remainingBalance={remainingBalance}
                  />
                );
              })}
            </Box>
            <br />
            <Box ml={1} mb={1}>
              <Button
                sx={[submitButtonStyle, { width: 200 }]}
                onClick={() =>
                  !showPasswordFields
                    ? setShowPasswordFields(true)
                    : handleSubmitPassword()
                }
              >
                Reset Password
              </Button>
            </Box>
            <Box ml={1}>
              {driverEnabled ? (
                <Button
                  variant="outlined"
                  sx={{
                    width: 200,
                    textTransform: 'none',
                    color: '#D93026',
                    borderColor: '#D93026',
                    '&:hover': {
                      backgroundColor: '#ffe6e6',
                      borderColor: '#D93026',
                    },
                  }}
                  onClick={() => setShowConfirmDriverState(true)}
                >
                  Deactivate Driver
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    width: 200,
                    textTransform: 'none',
                    color: '#181818',
                    borderColor: '#181818',
                    '&:hover': {
                      backgroundColor: '#cccccc',
                      borderColor: '#181818',
                    },
                  }}
                  onClick={() => setShowConfirmDriverState(true)}
                >
                  Activate Driver
                </Button>
              )}
            </Box>
          </>
        )}
        <Dialog
          open={showPasswordFields}
          onClose={() => setShowPasswordFields(false)}
          aria-labelledby="modal-title"
        >
          <Box m={2}>
            <Typography variant="h5" mb={2}>
              Reset Password
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                id="password"
                label="New Password"
                variant="outlined"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorMessage('');
                }}
                sx={{ marginBottom: 1, width: 408 }}
              />
              <TextField
                id="confirmPassword"
                label="Confirm New Password"
                variant="outlined"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setErrorMessage('');
                }}
                sx={{ marginBottom: 1, width: 408 }}
              />
            </Box>
            {errorMessage !== '' && (
              <Box m={1} my={2}>
                <Typography variant="body1" sx={{ color: 'red' }}>
                  {errorMessage}
                </Typography>
              </Box>
            )}
            <Box my={1}>
              <Button
                sx={[cancelButtonStyle, { width: 200, marginRight: 1 }]}
                onClick={() => {
                  setShowPasswordFields(false);
                  setErrorMessage('');
                }}
              >
                Cancel
              </Button>
              <Button
                sx={[submitButtonStyle, { width: 200 }]}
                onClick={() =>
                  !showPasswordFields
                    ? setShowPasswordFields(true)
                    : handleSubmitPassword()
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Dialog
          open={showConfirmDriverState}
          onClose={() => setShowConfirmDriverState(false)}
          aria-labelledby="modal-title"
        >
          <Box m={2}>
            <Typography mb={2} variant="h6">{`Are you sure you want to ${
              driverEnabled ? 'deactivate' : 'activate'
            } driver?`}</Typography>
            <Box>
              <Button
                variant="outlined"
                sx={[cancelButtonStyle, { width: 200, marginRight: 1 }]}
                onClick={() => setShowConfirmDriverState(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={[submitButtonStyle, { width: 200 }]}
                onClick={() =>
                  driverEnabled
                    ? handleDeactivateDriver()
                    : handleActivateDriver()
                }
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Dialog>
        <SuccessAlert
          message={message}
          open={openSuccess}
          handleClose={() => setOpenSuccess(false)}
        />
        <ErrorAlert
          message={message}
          open={openError}
          handleClose={() => setOpenError(false)}
        />
      </Box>
    </Paper>
  );
}
