export default {
  formId: 'creditApplicationForm',
  formField: {
    // BusinessInformation
    carrierType: {
      name: 'carrierType',
      label: 'Carrier Type *',
      requiredErrorMsg: 'Select a Carrier type',
    },
    businessName: {
      name: 'businessName',
      label: 'Business Name *',
      requiredErrorMsg: 'Business Name is required',
    },
    promotionCode: {
      name: 'promotionCode',
      label: 'Promotion Code',
    },
    businessPhoneNumber: {
      name: 'businessPhoneNumber',
      label: 'Business Phone Number *',
      requiredErrorMsg: 'Business Phone Number is required',
    },
    businessEmail: {
      name: 'businessEmail',
      label: 'Business Email *',
      requiredErrorMsg: 'Business Email is required',
    },
    businessEin: {
      name: 'businessEin',
      label: 'EIN *',
      requiredErrorMsg: 'EIN is required',
    },
    businessAddress1: {
      name: 'businessAddress1',
      label: 'Business Address Line 1 *',
      requiredErrorMsg: 'Address Line 1 is required',
    },
    businessAddress2: {
      name: 'businessAddress2',
      label: 'Business Address Line 2',
    },
    businessRegion: {
      name: 'businessRegion',
      label: 'State *',
      requiredErrorMsg: 'State is required',
    },
    businessCity: {
      name: 'businessCity',
      label: 'City *',
      requiredErrorMsg: 'City is required',
    },
    businessZipCode: {
      name: 'businessZipCode',
      label: 'Postal / Zip Code *',
      requiredErrorMsg: 'Postal / Zip Code is required',
    },
    businessCountry: {
      name: 'businessCountry',
      label: 'Country *',
      requiredErrorMsg: 'Country is required',
    },
    primaryContactName: {
      name: 'primaryContactName',
      label: 'Contact Name',
    },
    primaryContactEmail: {
      name: 'primaryContactEmail',
      label: 'Contact Email (If different from business email)',
    },
    primaryContactPhoneNumber: {
      name: 'primaryContactPhoneNumber',
      label: 'Contact Phone Number (If different from business phone number)',
    },

    // Fleet information
    driverId: {
      name: 'driverId',
      label: 'Driver ID',
      requiredErrorMsg: 'Driver ID is required',
    },
    numberOfDrivers: {
      name: 'numberOfDrivers',
      label: 'Number of drivers',
    },
    departmentOfTransportationNumber: {
      name: 'departmentOfTransportationNumber',
      label: 'DOT Number *',
      requiredErrorMsg: 'DOT Number is required',
    },
    motorCarrierNumber: {
      name: 'motorCarrierNumber',
      label: 'MC Number *',
      requiredErrorMsg: 'MC Number is required',
    },
    estimatedWeeklySpent: {
      name: 'estimatedWeeklySpent',
      label: 'Typical Weekly Fuel and Maintenance Spend *',
      requiredErrorMsg: 'Typical Weekly Spend is required',
      typeErrorMsg: 'Typical Weekly Spend must be a valid number',
    },

    // Owner information
    ownerlegalName: {
      name: 'ownerlegalName',
      label: 'Legal Name *',
      requiredErrorMsg: 'Legal Name is required',
    },
    ownerDateOfBirth: {
      name: 'ownerDateOfBirth',
      label: 'DOB *',
      requiredErrorMsg: 'DOB is required',
    },
    ownerAddress1: {
      name: 'ownerAddress1',
      label: 'Address Line 1 *',
      requiredErrorMsg: 'Address Line 1 is required',
    },
    ownerAddress2: {
      name: 'ownerAddress2',
      label: 'Address Line 2',
    },
    ownerRegion: {
      name: 'ownerRegion',
      label: 'State *',
      requiredErrorMsg: 'State is required',
    },
    ownerCity: {
      name: 'ownerCity',
      label: 'City *',
      requiredErrorMsg: 'City is required',
    },
    ownerZipCode: {
      name: 'ownerZipCode',
      label: 'Postal / Zip Code *',
      requiredErrorMsg: 'Postal / Zip Code is required',
    },
    ownerCountry: {
      name: 'ownerCountry',
      label: 'Country *',
      requiredErrorMsg: 'Country is required',
    },
    ownerSsn: {
      name: 'ownerSsn',
      label: 'SSN *',
      requiredErrorMsg: 'SSN is required',
    },
    ownerDriverLicense: {
      name: 'ownerDriverLicense',
      label: "US Driver's License",
    },
  },
};
