import { gql } from '@apollo/client';

export const START_TRANSACTION = gql`
  mutation StartTransaction(
    $timestamp: DateTime!
    $dispenser: Int!
    $locationUuid: String!
    $driverID: String!
    $extraData: TransactionExtraDataInput!
    $overrideLimits: [OverrideLimit]
    $extendedDate: DateTime
  ) {
    startTransaction(
      timestamp: $timestamp
      dispenser: $dispenser
      locationUuid: $locationUuid
      driverID: $driverID
      extraData: $extraData
      overrideLimits: $overrideLimits
      extendedDate: $extendedDate
    ) {
      id
      token
      status
    }
  }
`;
